@import '../../style.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');


@media only screen and (min-width: 980px){
    .home{
        .telephone{
            display: none;
        }
        width: 100%;
        position: relative;
        padding-top: 100px;
        background-color: #18191C;
        font-family: 'Lato', sans-serif;
        .block1{
            display: flex;
            max-width: 1200px;
            padding: 50px;
            margin-left: auto;
            margin-right: auto;
            .right{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50%;
                img{
                    width: 100%;
                    height: auto;
                }
            }
            .left{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 50px;
                width: 50%;
                color: white;
                .title{
                    font-size: 60px;
                    font-style: italic;
                    line-height: 1.1;
                    width: 400px;
                    span{
                        color: #FF1616;
                    }
                }
                .desc{
                    width: 400px;
                    font-family: 'Open Sans', sans-serif;
                }
            }
        }
        .block2{
            display: flex;
            flex-direction: column;
            max-width: 1200px;
            padding: 50px;
            margin-left: auto;
            margin-right: auto;
            justify-content: center;
            align-items: center;
            .top{
                display: flex;
                gap: 30px;
                hr{
                    width: 200px;
                    background-color: white;
                    z-index: 99;
                    border-color: white;
                    height: 5px;
                    // color: white;
                }
                h2{
                    color: white;
                    font-family: 'Open Sans', sans-serif;
                }
            }
            .bottom{
                display: flex;
                height: 500px;
                .right{
                    position: absolute;
                    margin-left: 450px;
                    margin-top: 150px;
                    transform: rotate(-15deg);
                    @include miniPc{
                        // height: 200px;
                        margin-left: 350px;
                    }
                    img{
                        border: 10px solid white;
                        width: 500px;
                        height: 300px;
                        @include miniPc{
                            height: 250px;
                            width: 400px;
                        }
                    }
                }
                .left{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // position: absolute;
                    font-size: 70px;
                    z-index: 2;
                    width: 900px;
                    // text-align: justify;
                    color: white;
                    line-height: 1.1;
                    text-shadow: red 0px 0px 20px;
                    font-family: 'Montserrat', sans-serif;
                    @include miniPc{
                        font-size: 50px;
                        width: 700px;
                    }
                }
            }
        }
        .block3{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: black;
            padding: 50px;
            h2{
                text-align: center;
                font-size: 50px;
                font-family: 'Montserrat', sans-serif;
                color: white;
                width: 800px;
            }
            .content{
                display: flex;
                gap: 50px;
                // align-items: center;
                .box{
                    display: flex;
                    gap: 50px;
                    justify-content: space-between;
                    // align-items: center;
                    .box-content{
                        img{
                            width: 200px;
                            height: 250px;
                            border-radius: 20px;
                            @include miniPc{
                                width: 200px;
                                height: 250px;
                            }
                        }
                        .comment{
                            color: white;
                            position: absolute;
                            height: 70px;
                            width: 200px;
                            background-color: rgb(255, 22, 22, 0.8);
                            margin-top: -70px;
                            text-align: center;
                            font-family: 'Gilda Display', serif;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 0 0 20px 20px;
                            text-transform: uppercase;
                            @include miniPc{
                                width: 200px;
                            }
                        }
                        .desc{
                            width: 200px;
                            text-align: justify;
                            color: white;
                            font-family: 'Open Sans', sans-serif;
                            @include miniPc{
                                width: 200px;
                            }
                        }
                    }
                }
            }
        }
        .block4{
            display: flex;
            flex-direction: column;
            background-color: #18191C;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding-top: 20px;
            padding-bottom: 150px;
            gap: 50px;
            h2{
                font-family: 'Montserrat', sans-serif;
                font-size: 50px;
                color:white;
                width: 800px;
            }
            .content{
                border-radius: 30px;
                width:600px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 30px;
                padding: 25px;
                // height: 300px;
                .box{
                    display: flex;
                    gap: 100px;
                    img{
                        width: 100px;
                        height: auto;
                    }
                }
            }
        }
        .block5{
            background-color: black;
            display: flex;
            flex-direction: column;
            text-align: center;
            gap: 50px;
            align-items: center;
            padding-top: 20px;
            height: 1000px;
            h2{
                font-family: 'Montserrat', sans-serif;
                color: white;
                font-size: 50px;
                margin-left: auto;
                margin-right: auto;
                width: 800px;
            }
            .content{
                display: flex;
                justify-content: center;
                gap: 0px;
                
                .box1{
                    width: 200px;
                    height: 150px;
                    img{
                        border-radius: 40%;
                        width: 100%;
                        height: 100%;
                    }
                }
                .box2{
                    width: 500px;
                    height: 300px;
                    position: relative;
                    margin-top: 150px;
                    img{
                        border-radius: 100px;
                        width: 100%;
                        height: 100%;
                    }
                }
                .box3{
                    width: 200px;
                    height: 150px;
                    .img1{
                        width: 100%;
                        height: 100%;
                    }
                    .img2{
                        border-radius: 40%;
                        width: 100%;
                        height: 100%;
                        position: relative;
                        left: 50px;
                    }
                }
                // .box2{
                    //     display: flex;
                    //     flex-direction: column;
                    //     width: 50%;
                    //     img{
                        //         width: 100%;
                        //     }
                        // }
                    }
                    .txt-btn{
                        position: relative;
                        display: flex;
                        text-align: start;
                        // background-color: red;
                        gap: 10px;
                        left: -200px;
                        @include miniPc{
                            left: -150px;
                        }
                        span{
                            font-size: 25px;
                            color: white;
                            font-family: 'Open Sans', sans-serif;
                        }
                        button{
                            background-color: red;
                            border: none;
                            width: 150px;
                            border-radius: 20px;
                            color: white;
                            font-family: 'Open Sans', sans-serif;
                            height: 45px;
                        }
                        button:hover{
                            box-shadow: 0px 0px 10px 0px red;
                        }
                    }
                }
            }
}

@media only screen and (max-width: 980px){
    .home{
        width: 100%;
        position: relative;
        margin-top: 0px;
        background-color: #18191C;
        font-family: 'Lato', sans-serif;
        .ordinateur{
            display: none;
        }
        .telephone{
    
            .first-block{
                background-image: linear-gradient(transparent, rgba(255, 255, 255, 0.1)), url('../../assets/images/1.jpg');
                height: 100vh;
                width: 100%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                position: relative;
                backdrop-filter: blur(10px);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: white;
                .content{
                    background-image: radial-gradient(rgba(255, 0, 0, 0.1) 0.05%, transparent);
                    display: flex;
                    flex-direction: column;
                    max-width: 500px;
                    
                    .title{
                        font-size: 40px;
                        font-family: 'Montserrat', sans-serif;
                        text-align: center;
                        // background: radial-gradient(to right, rgba(255, 0, 0, 0.1), transparent);
                        width: auto;
                        text-transform: uppercase;
                    }
                    .desc{
                        padding: 20px;
                        font-family: 'Gilda Display', serif;
                    }
                }
            }
            .second-block{
                display: flex;
                flex-direction: column;
                padding: 20px;
                background-color: #18191C;
                color: white;
                max-width: 500px;
                margin-left: auto;
                margin-right: auto;
        
                .title{
                    text-align: center;
                    font-family: 'Montserrat', sans-serif;
                    font-size: 25px;
                }
                .content{
                    display: flex;
                    height: 200px;
                    width: 100%;
                    .left{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 200px;
                        width: 50%;
                        span{
                            font-family: 'Gilda Display', serif;
                            font-size: 18px;
                            width: 200px;
                        }
                        .btn{
                            background: radial-gradient(rgb(255, 0, 0, 0.5), black);
                            width: 200px;
                            height: 100px;
                            button{
                                height: 30px;
                                width: 150px;
                                background-color: #FF1616;
                                border: none;
                                color: white;
                                border-radius: 12px;
                            }
                        }
                    }
                    .beautiful-girl-right{
                        padding: 0;
                        margin: 0;
                        width: 50%;
                        height: 200px;
                        display: flex;
                        justify-content: end;
                        img{
                            border: 4px solid red;
                            width: 150px;
                            height: 200px;
                            border-radius: 20px;
                        }
                    }
                }
            }
            .third-block{
                display: flex;
                flex-direction: column;
                background-color: #18191C;
                justify-content: center;
                align-items: center;
                padding: 5px;
                padding-top: 100px;
                h2{
                    text-align: center;
                    font-size: 25px;
                    font-family: 'Montserrat', sans-serif;
                    color: white;
                }
                .content{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    max-width: 500px;
                    gap: 20px;
                    .box{
                        display: flex;
                        justify-content: space-between;
                        // gap: 20px;
                        width: 100%;
                        .box-content{
                            img{
                                width: 170px;
                                height: 220px;
                                border-radius: 20px;
                            }
                            .comment{
                                color: white;
                                position: absolute;
                                height: 70px;
                                width: 170px;
                                background-color: rgb(255, 22, 22, 0.8);
                                margin-top: -70px;
                                text-align: center;
                                font-family: 'Gilda Display', serif;
                                display: flex;
                                justify-content: center;
                                text-transform: uppercase;
                                align-items: center;
                                border-radius: 0 0 20px 20px;
                            }
                        }
                    }
                }
            }
            .fourth-block{
                display: flex;
                flex-direction: column;
                background-color: #18191C;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding-top: 100px;
                h2{
                    font-family: 'Montserrat', sans-serif;
                    font-size: 25px;
                    color:white;
                }
                .content{
                    border-radius: 50px;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    padding: 25px;
                    width: 100%;
                    max-width: 500px;
                    justify-content: center;
                    align-items: center;
                    // height: 300px;
                    .box{
                        display: flex;
                        gap: 30px;
                        img{
                            width: 70px;
                            height: auto;
                        }
                    }
                }
            }
            .five-block{
                background-color: #18191C;
                display: flex;
                flex-direction: column;
                padding-top: 100px;
                text-align: center;
                gap: 50px;
                h2{
                    font-family: 'Montserrat', sans-serif;
                    color: white;
                    font-size: 25px;
                    width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                }
                .content{
                    display: flex;
                    width: 100%;
                    max-width: 500px;
                    margin-left: auto;
                    margin-right: auto;
                    .box1{
                        width: 50%;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .box2{
                        display: flex;
                        flex-direction: column;
                        width: 50%;
                        img{
                            width: 100%;
                        }
                    }
                }
            }
            .six-block{
                position: relative;
                display: flex;
                flex-direction: column;
                padding: 100px 0px 100px 0px;
                justify-content: center;
                text-align: center;
                width: 100%;
                max-width: 500px;
                margin-left: auto;
                margin-right: auto;
                .title{
                    font-size: 25px;
                    font-weight: bold;
                }
                img{
                    height: 600px;
                    width: 90%;
                    position: relative;
                    margin-left: auto;
                    margin-right: auto;
                }
                .content{
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    // margin-left: auto;
                    // margin-right: auto;
                    text-align: center;
                    gap: 50px;
                    color: white;
                    font-family: 'Gilda Display', serif;
                    .six-block-top{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 30px;
                        img{
                            width: 250px;
                            border-radius: 50px;
                            height: 200px;
                        }
                    }
                }
            }
        }
    }

}