@tailwind base;
@tailwind components;
@tailwind utilities;
.custom-scrollbar::-webkit-scrollbar {
  @apply w-2 h-2;
}

.custom-scrollbar::-webkit-scrollbar-track {
  @apply bg-transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-black-10 rounded-lg;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  @apply bg-black-10;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


$themes: (
    dark: (
    textColor: whitesmoke,
    test:black,
    bg: #1B2631,
    logo: white,
    bgSoft: #333,
    textColorSoft: lightgray,
    border: #444,
    burger: white,
    box: #5D6D7E,
    sn: white,
    ta:#1B2631,
  ),
  light: (
    textColor: #000,
    bg: #EDEDED,
    test:red,
    logo: darkblue,
    bgSoft: #f6f3f3,
    textColorSoft: #555,
    border: lightgray,
    burger: black,
    box: #ffffff,
    sn: #3164F4,
    ta: white
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

@mixin mobile {
  @media (max-width: 480px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: 960px) {
    @content;
  }
}
@mixin miniPc {
  @media (max-width: 1119px) {
    @content;
  }
}