@import '../../style.scss';

.loader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .newtons-cradle {
        --uib-speed: 1.2s;
        --uib-color: white;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .newtons-cradle__dot {
        position: relative;
        display: flex;
        align-items: center;
        height: 10px;
        width: 10px;
        transform-origin: center top;
        }
        
        .newtons-cradle__dot::after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--uib-color);
        }
        
        .newtons-cradle__dot:first-child {
        animation: swing var(--uib-speed) linear infinite;
        }
        
        .newtons-cradle__dot:last-child {
        animation: swing2 var(--uib-speed) linear infinite;
        }
        
        @keyframes swing {
        0% {
            transform: rotate(0deg);
            animation-timing-function: ease-out;
        }
        
        25% {
            transform: rotate(70deg);
            animation-timing-function: ease-in;
        }
        
        50% {
            transform: rotate(0deg);
            animation-timing-function: linear;
        }
        }
        
        @keyframes swing2 {
        0% {
            transform: rotate(0deg);
            animation-timing-function: linear;
        }
        
        50% {
            transform: rotate(0deg);
            animation-timing-function: ease-out;
        }
        
        75% {
            transform: rotate(-70deg);
            animation-timing-function: ease-in;
        }
        }                       
    }
    .spinner {
        width: 56px;
        height: 56px;
        display: grid;
        border: 4.5px solid #0000;
        border-radius: 50%;
        border-color: #F5B7B1 #0000;
        animation: spinner-e04l1k 1s infinite linear;
    }
    .spinner::before,
    .spinner::after {
      content: "";
      grid-area: 1/1;
      margin: 2.2px;
      border: inherit;
      border-radius: 50%;
    }
    
    .spinner::before {
      border-color: red #0000;
      animation: inherit;
      animation-duration: 0.5s;
      animation-direction: reverse;
    }
    
    .spinner::after {
      margin: 8.9px;
    }
    
    @keyframes spinner-e04l1k {
      100% {
        transform: rotate(1turn);
      }
    }
      
}