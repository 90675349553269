@import "../../style.scss";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');

.about-page {
  background-color: white;
  font-family: 'Lato', sans-serif;
  .background-image {
    background-size: cover;
    width: 100%;
    height: auto;
    &::before {
      content: "";
      margin-top: 100px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: auto;
      height: 70%;
      background-repeat: no-repeat;
      background-position: center top;
      background-size: contain;
      transform: translateY(-23%);
      z-index: 1;
    }
  }

  .content-container {
    padding-top: 15%;
    position: relative;
    z-index: 1;
    display: flex;
    background-color: black;
    flex-direction: column;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
  }

  .about-us,
  .our-mission,
  .slogan {
    color: white;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
  }

  .our-mission {
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 100px;
    justify-content: space-between;
    width: 100%;
    max-width: 700px;
    .mission-image {
      width: 50%;
      img {
        width: 300px;
        height: auto;
        margin-right: 10px;
        transform: scaleX(-1);
      }
    }
    .mission-text{
      text-align: justify;
      h2{  
        text-transform: uppercase;
      }
    }
  }

  .slogan {
    display: flex;
    align-items: center;
    position: relative;

    .slogan-text {
      position: absolute;
      top: 45%;
      left: 30%;
      transform: translate(-50%, -50%);
      margin: 0;
      z-index: 1;
      width: 200px;
    }

    .slogan-image {
      img {
        width: auto;
        height: auto;
        margin-right: 10px;
        transform: scaleX(-1);
      }
    }
  }

  .newsletter {
    background-color: black;
    margin-left: 3%;
    margin-right: 3%;
    color: white;
    border-radius: 20px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
    h2 {
      margin-bottom: 10px;
    }
    .newsletter-form{
      background-color: white;
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      // border: 2px solid red;
      border-radius: 20px;
      height: 40px;
      input{
        width: 80%;
        background-color: transparent;
        border: none;
        padding: 10px;
        color: black;
        outline: none;
      }
      button{
        position: relative;
        width: 20%;
        border:none;
        background-color: red;
        border-radius: 0px 20px 20px 0px;
        height: 40px;
        top: -2px;
        color: white;
      }
    }
  }
  .four-phrases {
    display: flex;
    flex-direction: column;
    padding-left: 2%;
    p {
      margin: 3px;
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 700px) {
  .about-page {
    background-color: white;
    overflow-x: hidden;
    .background-image {
      background-image: url("../../assets/images/propos1.jpg");
      background-size: cover;
      width: 100%;
      height: 350px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: auto;
        height: 70%;
        background-image: linear-gradient(
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)
          ),
          url("../../assets/images/propos2.png");
        background-repeat: no-repeat;
        background-position: center top;
        background-size: contain;
        transform: translateY(-23%);
        z-index: 1;
      }
    }

    .content-container {
      position: relative;
      z-index: 1;
      display: flex;
      background-color: black;
      border-radius: 20px;
      align-items: center;
      justify-content: center;
      margin-top: -110px;
    }

    .about-us,
    .our-mission,
    .slogan {
      color: white;
      border-radius: 10px;
      padding: 10px;
      text-align: center;
      word-wrap: break-word;
    }

    .our-mission {
      display: flex;
      align-items: center;
      position: relative;
      padding-top: 50px;

      .mission-image {
        img {
          width: auto;
          height: auto;
          margin-right: 10px;
        }
      }

      .mission-text {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        z-index: 1;
        width: 80%;
      }
    }

    .slogan {
      display: flex;
      align-items: center;
      position: relative;
      margin-top: -100px;
      .slogan-text {
        position: absolute;
        top: 40%;
        left:40%;
        transform: translate(-50%, -50%);
        margin: 0;
        z-index: 1;
      }

      .slogan-image {
        img {
          width: auto;
          height: auto;
          margin-right: 10px;
        }
      }
    }

    .newsletter {
      background-color: black;
      margin-left: 3%;
      margin-right: 3%;
      color: white;
      border-radius: 20px;
      padding: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
      justify-content: center;
      display: flex;
      flex-direction: column;

      h2 {
        margin-bottom: 10px;
      }

      .newsletter-form {
        display: flex;
        // gap: 10px;
        flex-wrap: column;
        justify-content: center;
        align-items: center;
        // margin-top: 10px;
        border: 2px solid white;
        border-radius: 10px;
        height: 40px;
        width: 100%;
        
        input[type="email"] {
          color: white;
          // padding: 10px;
          width: 70%;
          border: none;
          background-color: transparent;
          outline: none;
        }
        
        button {
          color: white;
          height: 40px;
          border: none;
          background-color: red;
          border-radius: 0px 8px 8px 0px;
          width: 30%;
          position: relative;
          top: 0px;
          left: 2px;
        }
      }
    }

    .four-phrases {
      display: flex;
      flex-direction: column;
      padding-left: 2%;

      p {
        margin: 3px;
        font-weight: bold;
      }
    }
  }
}
