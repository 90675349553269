.all {
  display: flex;
  justify-content: center;
  color: white;
  margin-right: 10px;
  margin-left: 10px;
  font-family: "Lato", sans-serif;

  .podcast {
    display: flex;
    justify-content: center;
    width: 1000px;
    flex-direction: column;
    padding-top: 200px;

    .part1 {
      display: flex;
      width: 100%;
      margin-bottom: 100px;

      .left {
        width: 70%;
        margin-right: 20px;

        .first {
          font-weight: bold;
          font-size: 20px;
        }

        .audio-container {
          flex-grow: 1;
          flex-shrink: 1;
          display: flex;

          .rap-container {
            max-width: none;

            .rap-pp-button {
            }

            .rap-volume {
              border-radius: 160px;
            }

            .rap-slider .rap-progress {
              background-color: red;

              .rap-pin {
                background-color: red;
              }
            }
          }

          .audio-player {
            background-color: black;
            color: red;
            width: 100%;
          }

          .download-icon {
            margin-left: 6px;
            margin-top: 6px;
            transition: color 0.3s;
          }

          .download-icon:hover,
          .download-icon:active {
            color: red;
          }
        }

        div {
          margin-bottom: 10px;
        }
      }

      .right {
        width: 30%;

        img {
          width: 80%;
          height: 90%;
          margin-bottom: 15px;
          border-radius: 5px;
        }

        div:nth-child(2) {
          font-weight: bold;
          font-size: 20px;
        }

        div:nth-child(3) {
          color: red;
          font-weight: bold;
        }
      }

      @media (max-width: 950px) {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        .right {
          width: 100%;
          margin-bottom: 40px;
          text-align: center;

          img {
            width: 200px;
            height: 200px;
            border-radius: 5px;
          }

          @media (max-width: 650px) {
            img {
              width: 160px;
              height: 160px;
              border-radius: 5px;
            }
          }
        }
        .left {
          width: 100%;
          text-align: center;
          margin-right: 0;

          .audio-container {
            .rap-container {
              padding-right: 0;
              padding-left: 0;
              width: 100%;
            }

            .rap-container .rap-controls {
              cursor: default;
              display: block;
              margin-left: 4px;
              margin-right: 4px;

              .rap-current-time {
                margin-right: 80%;
                padding-bottom: 30px;
              }

              .rap-total-time {
                margin-left: 80%;
                margin-top: 30px;
              }

              .rap-slider {
                margin: 10px 5px;
              }
            }
          }
        }
      }
      @media (max-width: 950px) {
        margin-bottom: 30px;
      }
    }

    .part2 {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .header-container {
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        select{
          width: 200px;
          border-radius: 8px;
          outline: none;
        }
        .span1 {
          font-size: 25px;
        }

        button {
          background: red;
          font-weight: bold;
          border-radius: 5px;
          border: none;
          padding: 7px;
          color: white;
          transition: background-color 0.3s;

          &:hover {
            background: darkred;
          }

          &:active {
            background: maroon;
          }
        }
      }

      .podcast-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 35px;
        padding-bottom: 35px;

        .left-part2 {
          display: flex;
          width: 70%;
          padding-right: 20px;
          border-right: 1px solid #ffffff;

          .logo-container {
            margin-right: 20px;

            img {
              width: 180px;
              height: 180px;
              border-radius: 5px;
            }
          }

          .audio-container {
            flex-grow: 1;
            flex-shrink: 1;

            .rap-container {
              max-width: none;

              .rap-pp-button {
              }

              .rap-volume {
                border-radius: 160px;
              }

              .rap-slider .rap-progress {
                background-color: red;

                .rap-pin {
                  background-color: red;
                }
              }
            }


            .audio-player {
              background-color: black;
              color: red;
              width: 100%;
            }


            .titre-podcast {
              color: red;
              margin-bottom: 20px;
            }

            .detail-podcast {
              margin-bottom: 20px;
            }

            .ico {
              display: flex;
              align-items: center;

              .rap-container {
                max-width: none;
                width: 100%;
                height: 36px;
                margin-right: 10px;
              }

              .download-icon {
                transition: color 0.3s;
              }

              .download-icon:hover,
              .download-icon:active {
                color: red;
              }
            }
          }
        }

        .right-part2 {
          display: flex;
          width: 30%;
          flex-direction: column;
          justify-content: center;
          padding-left: 20px;

          .detail {
            margin-bottom: 10px;
          }
        }

        @media (max-width: 950px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 15px;
          .left-part2 {
            width: 100%;
            text-align: center;
            margin-bottom: 20px;
            border-right: none;
            padding-right: 0;

            .logo-container {
              margin-right: 0;

              img {
                width: 160px;
                height: 160px;
                border-radius: 5px;
              }
            }

            .audio-container {
              .detail-podcast {
                margin-bottom: 35px;
              }

              .rap-container {
                margin-left: 5px;
                padding-right: 0;
                padding-left: 0;
                width: 100%;

                .rap-controls {
                  cursor: default;
                  display: block;
                  margin-left: 4px;
                  margin-right: 4px;

                  .rap-current-time {
                    margin-right: 80%;
                    padding-bottom: 30px;
                  }

                  .rap-total-time {
                    margin-left: 80%;
                    margin-top: 30px;
                  }

                  .rap-slider {
                    margin: 10px 5px;
                  }
                }
              }
            }
          }
          .right-part2 {
            width: 100%;
            padding-left: 30px;
            margin-bottom: 25px;
          }
          .splitLine {
            width: 70%;
            border: 1px solid #c2bebe;
          }
        }
        @media (max-width: 650px) {
          .left-part2 {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    .part3 {
      .header-container {
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        .span1 {
          font-size: 22px;
        }

        button {
          background: black;
          font-weight: bold;
          border-radius: 5px;
          border: none;
          padding: 7px;
          color: white;
          transition: background-color 0.3s;

          &:hover {
            background: darkred;
          }

          &:active {
            background: maroon;
          }
        }
      }

      .main-part {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .item-emission {
        width: calc(25% - 20px);
        margin-bottom: 20px;

        img {
          width: 100%;
          height: auto;
          border-radius: 5px;
        }

        div:nth-child(2) {
          font-weight: bold;
          font-size: 20px;
        }

        div:nth-child(3) {
          color: red;
          font-weight: bold;
        }
      }

      @media (max-width: 950px) {
        .main-part {
          .item-emission {
            width: calc(32% - 20px);
          }
        }
        @media (max-width: 650px) {
          .main-part {
            .item-emission {
              width: calc(50% - 20px);
            }
          }
        }
      }
    }

  }
}
