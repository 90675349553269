@import '../../style.scss';

.add_music{
    cursor: pointer;
    .reject{
        background-color: white;
        height: 300px;
        position: fixed;
        z-index: 999;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30%;
        @include tablet{
            width: 90%;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        .icon{
            position: absolute;
            top: 10px;
            right: 10px;
            color:#ff1616;
            cursor: pointer;
        }
        form{
            display: flex;
            flex-direction: column;
            justify-content: center;
            label{
                display: flex;
                gap: 10px;
                align-items: center;
                cursor: pointer;
            }
            button{
                background-color:#ff1616;
                border: none;
                border-radius: 8px;
                height: 40px;
                color: white;
                font-family: 'Montserrat', sans-serif;
                position: relative;
                top: 20px;
            }
            input[type="checkbox"] {
                display: none;
            }
            
            /* Styles pour le faux élément de case à cocher personnalisée */
            .custom-checkbox {
                width: 15px;
                height: 15px;
                background-color: white;
                border: 2px solid gray;
                border-radius: 4px;
                display: inline-block;
                cursor: pointer;
                transition: background-color 0.3s, border-color 0.3s;
            }
            input[type="checkbox"]:checked + .custom-checkbox {
                background-color:#ff1616;
                border-color: #ff1616;
            }
            input[type="checkbox"]:not(:checked) + .custom-checkbox:hover {
                background-color: lightgray;
                border-color: lightgray;
            }
        }
    }
    .box{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        color: #5f6065;
        .more{
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            left: -30px;
            text-align: center;
            color: white;
            transition: 5s;
            animation-name: widthAnimation;
            animation-duration: .2s;
            animation-timing-function: ease;
            animation-fill-mode: forwards;
            height: 0px;
            overflow: hidden;
            width: 100px;
            button{
                background-color: #ff1616;
                border: none;
                border-radius: 10px;
                width: 100px;
                color: white;
                font-family: 'Montserrat', sans-serif;
            }
            button.enCours{
                background-color: yellow;
                color: #c2c2c2;
            }
            button.publie{
                background-color: green;
            }
        }
        @keyframes widthAnimation {
            0% {
                height: 0;
            }
            50% {
                height: 20;
            }
            100% {
                height: 65px;
            }
            }
        .titre{
            width: 50%;
            display: flex;
            gap: 10px;
            @include tablet{
                width: 31%;
            }
            img{
                width: 50px;
                height: 50px;
                @include tablet{
                    width: 30px;
                    height: 30px;
                }
            }
            .content{
                display: flex;
                flex-direction: column;
                justify-content: center;
                h4{
                    font-size: 16px;
                    font-family: 'Montserrat', sans-serif;
                    @include tablet{
                        font-size: 12px;
                    }
                }
                span{
                    font: 12px;
                    @include tablet{
                        font-size: 10px;
                    }
                }
            }
        }
        .date{
            width: 18%;
            font-size: 16px;
            font-family: 'Montserrat', sans-serif;
            @include tablet{
                font-size: 12px;
                width: 23%;
            }
        }
        .status{
            width: 16%;
            font-size: 16px;
            font-family: 'Montserrat', sans-serif;
            display: flex;
            align-items: center;
            gap: 5px;
            overflow: hidden;
            right: 5px;
            @include tablet{
                font-size: 8px;
                word-break: break-word;
                width: 23%;
            }
            .statusDoIcon{
                width: 15px;
                height: 15px;
                background-color: green;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                .icon{
                    font-size: 14px;
                    color: white;
                }
            }
            .statusNoIcon{
                width: 15px;
                height: 15px;
                background-color: white;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                .icon{
                    font-size: 14px;
                    color:#ff1616;
                }
            }
            .statusInIcon{
                width: 15px;
                height: 15px;
                background-color: yellow;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                .icon{
                    font-size: 14px;
                    color: black;
                }
            }
            .statusRejectIcon{
                width: 15px;
                height: 15px;
                background-color: red;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                .icon{
                    font-size: 14px;
                    color: black;
                }
            }
        }
        .promute{
            width: 16%;
            @include tablet{
                width: 23%;
            }
            .promute-btn{
                width: 100%;
                position: relative;
                @include tablet{
                    font-size: 8px;
                    word-break: break-word;
                }
                display: flex;
                justify-content: center;
                align-items: center;
                .button-pay{
                    display: flex;
                    background-color: red;
                    width: 98%;
                    border-radius: 20px;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-left: 5px;
                    padding-right: 5px;
                    button{
                        width: 90%;
                        border: none;
                        gap: 20px;
                        background-color: transparent;
                        border: none;
                        color: white;
                        @include tablet{
                            width: 85%;
                            word-break: break-word;
                            gap: 5px;
                            justify-content: space-between;
                        }
                    }
                }
                .help-box{
                    position:absolute;
                    font-size: 10px;
                    background-color: white;
                    top: -90px;
                    padding: 5px;
                    border-radius: 10px;
                    display: none;
                    @include tablet{
                        font-size: 6px;
                    }
                }
                .help-box.display{
                    display: block;
                }
                .button-make-pub{
                    display: flex;
                    background-color: yellow;
                    width: 98%;
                    border-radius: 20px;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-left: 5px;
                    padding-right: 5px;
                    button{
                        width: 90%;
                        border: none;
                        gap: 20px;
                        background-color: transparent;
                        border: none;
                        color: white;
                        @include tablet{
                            width: 85%;
                            word-break: break-word;
                            gap: 5px;
                            justify-content: space-between;
                        }
                    }
                }

                .pub-in-progress{
                    background-color: green;
                    width: 98%;
                    border: none;
                    border-radius: 20px;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
                    padding-left: 5px;
                    padding-right: 5px;
                    @include tablet{
                        width: 90%;
                        word-break: break-word;
                        gap: 5px;
                        justify-content: space-between;
                    }
                }

            }
        }

    }
    .MusicShowMore{
        width: 100%;
        color: white;
        margin-top: 20px;
        table{
            width: 100%;
            margin-right: auto;
            margin-left: auto;
            tbody{
                tr{
                    td{
                        display: flex;
                        justify-content: space-between;
                        .imgTelecharge{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            a{
                                color: white;
                            }
                            img{
                                width: 50px;
                                height: 50px;
                            }
                        }
                        @include tablet{

                            audio{
                                width: 200px;
                            }
                        }
                        button{
                            background-color: #ff1616;
                            border: none;
                            border-radius: 10px;
                            width: 100px;
                            color: white;
                            font-family: 'Montserrat', sans-serif;
                        }
                        button.enCours{
                            background-color: yellow;
                            color: #c2c2c2;
                        }
                        button.publie{
                            background-color: green;
                        }
                    }
                }
            }
        }
    }
}