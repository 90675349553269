@import '../../style.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');


@media only screen and (max-width: 980px){
  .CDropdownMenu {
    background-color: black; 
    border-radius: 20px;
      text-align: center;
    a {
      padding-top: 15%;
      color: white;
      
      &:hover { 
        border-radius: 20px;
        color: white;
      }
    }
  }
  .navbar{
    background-color: red;
    height: 160px;
    width: 100%;
    position: fixed;
    z-index: 99;
    // font-family: 'Lato', sans-serif;
    .ordinateur{
      display: none;
    }
    .tel{
      width: 100%;
      height: 100%;
      .tel-content{
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        .tel-content-top{
            height: 50px;
            width: 100%;
            background-color: red;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            top: 0;
            .title{
              color: #ffffff;
              font-size: 10px;
              text-transform: none;
              width: 70%;
              text-align: center;
            }
            .title::before {
              width: 18px;
              height: 18px;
            }
            
            .title::after {
              width: 18px;
              height: 18px;
              animation: pulse 1s linear infinite;
            }
            
            .title::before,
            .title::after {
              position: absolute;
              content: " ";
              border-radius: 50%;
              background-color: #ffffff;
              left: 10%;
              top: 15px;
    
            }
        }
        .tel-content-bottom{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 95px;
          width: 100%;
          background-color: black;
          .content-icon{
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
            color: white;
            font-size: 20px;
            .icon{
              font-size: 40px;
              position: relative;
              left: 10px;
            }
          }
          .image{
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img{
              height: 80px;
              width: 80px;
              border: 5px solid red;
              border-radius: 50%;
            }
          }
          .btn{
            width: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            button{
              background-color: transparent;
              border: none;
              color: white;
              text-decoration: underline;
              font-size: 14px;
            }
          }

        }
      }
      .menu{
        position: absolute;
        height: 100vh;
        top: 0px;
        width: 100%;
        background-color: rgb(0, 0, 0, .8);
        .top{
          display: flex;
          justify-content: center;
          align-items: center;
          .icon{
            position: absolute;
            left: 10px;
            color: red;
            top: 20px;
            font-size: 40px;
            font-weight: bold;
          }
          span{
            color: white;
            position: relative;
            top: 25px;
            font-size: 20px;
            font-family: 'Open Sans', sans-serif;
          }
        }
        hr{
          width: 100%;
          height: 2px;
          background-color: white;
          position: relative;
          top: 20px;
        }
        .bottom{
          display: flex;
          flex-direction: column;
          text-align: center;
          position: relative;
          top: 50px;
          gap: 20px;
          .txt{
            font-size: 20px;
            text-decoration: none;
            color: white;
          }
        }
      }
      .menu.inactive{
        left: -100%;
        transition: 0.5s;
      }
      .menu.active{
        left: 0;
        transition: 0.5s;
      }

    }
  }
}

@media only screen and (min-width: 981px){
  .CDropdownMenu {
    padding-top: 20%;
    background-color: black; 
    border-radius: 20px;
      text-align: center;
    
    a {
      color: white;
      &:hover {
        background-color: red; 
        border: 2px solid red;
        border-radius: 20px;
        color: white;
      }
    }
  }
  .navbar{
    .tel{
      display: none;
    }
    background-color: black;
    height: 160px;
    width: 100%;
    position: fixed;
    z-index: 99;
    font-family: 'Lato', sans-serif;
    .ordinateur{
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .top{
        height: 50px;
        width: 100%;
        background-color: red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title{
          color: #ffffff;
        }
        .title::before {
          width: 18px;
          height: 18px;
        }
        
        .title::after {
          width: 18px;
          height: 18px;
          animation: pulse 1s linear infinite;
        }
        
        .title::before,
        .title::after {
          position: absolute;
          content: " ";
          border-radius: 50%;
          background-color: #ffffff;
          left: 25%;
          top: 26px;

        }
      }
      .bottom{
        justify-content: center;
        align-items: center;
        height: 80px;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        .left{
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: start;
          position: relative;
          img{
            border: 5px solid red;
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }
        }
        .middle{
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;
          .content-txt{
            width: 200px;
            border-radius: 10px;
            .txt{
              color: white;
              text-decoration: none;
              font-size: 16px;
              font-family: 'Open Sans', sans-serif;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              
            }
          }
          .content-txt:hover{
            background-color: red;
            box-shadow: 0px 0px 10px 0px red;
            transition: 1s;
          }
          .content-txt.active{
            background-color: red;
            box-shadow: 0px 0px 10px 0px red;
          }
        }
        .right{
          width: 25%;
          display: flex;
          justify-content: end;
          align-items: center;
          gap: 10px;
          .btn1{
            background-color: transparent;
            color: red;
            // text-decoration: underline;
            font-size: 16px;
            font-family: 'Open Sans', sans-serif;
            border: none;
            text-shadow: white 0px 0px 10px;
          }
          .btn2{
            background-color: red;
            border: none;
            height: 50px;
            width: 100px;
            color: white;
            font-family: 'Open Sans', sans-serif;
            border-radius: 50px;
          }
          .btn2:hover{
            box-shadow: 0px 0px 10px 0px red;
          }
        }
        display: flex;

      }
    }
  }
}

@keyframes pulse {
  from {
    transform: scale(0.9);
    opacity: 1;
  }

  to {
    transform: scale(1.8);
    opacity: 0;
  }
}