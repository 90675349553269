@import '../../style.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');

.label{
    background-color: #18191C;
    font-family: 'Lato', sans-serif;
    .first-block{
        background-image: url("../../assets/images/femme2.jpg");
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 700px;
        backdrop-filter: blur(10px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .block-blur{
            width: 100%;
            height: 700px;
            position: absolute;
            background-color: rgb(0, 0, 0, .2);
            top: 0px;
        }
        .title{
            position: relative;
            color: white;
            font-size: 70px;
            text-align: center;
            text-transform: uppercase;
            // top: -100px;
            max-width: 900px;
            margin-left: auto;
            margin-right: auto;
            font-weight: bold;
            margin-top: 100px;
            @include tablet{
                font-size: 40px;
            }
        }
        span{
            position: relative;
            color: white;
            font-size: 30px;
            font-family: 'Gilda Display', serif;
            text-align: center;
            padding: 20px;
            // top: 400px;
            max-width: 900px;
            margin-left: auto;
            margin-right: auto;
            @include tablet{
                font-size: 16px;
            }
        }
    }
    .second-block{
        background-image: url('../../assets/images/The lord.png');
        background-size: cover;
        background-repeat: none;
        background-position: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 450px;
        .block-blur{
            position: absolute;
            width: 100%;
            height: 450px;
            background-color: red;
            opacity: .7;
        }
        .top{
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            padding: 20px;
            gap: 10px;
            margin-top: -150px;
            max-width: 900px;
            img{
                width: 50%;
                height: 250px;
                border-radius: 30px;
                object-fit: cover;
            }
            .texte{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: -30px;
                h2{
                    color: white;
                    font-size: 35px;
                    font-family: 'Montserrat', sans-serif;
                    @include tablet{
                        font-size: 25px;
                    }
                }
                span{
                    color: white;
                    font-size: 14px;
                    text-align: center;
                    font-family: 'Gilda Display', serif;
                    font-weight: bold;
                    max-width: 400px;
                    @include tablet{
                        font-size: 12px;
                    }
                }
            }
        }
        .bottom{
            display: flex;
            flex-direction: column;
            margin-top: 250px;
            position: absolute;
            width: 100%;
            text-align: center;
            max-width: 900px;
            h2{
                color: white;
                font-size: 25px;
                font-family: 'Gilda Display', serif;
                font-weight: bold;
            }
            .content{
                display: flex;
                gap: 50px;
                width: 80%;
                height: 50px;
                margin-left: auto;
                margin-right: auto;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                img{
                    width: 40px;

                }
            }
        }
    }
    .third-block{
        background-size: cover;
        background-repeat: none;
        background-position: center;
        background-color: #18191C;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 450px;
        .block-blur{
            position: absolute;
            width: 100%;
            height: 450px;
            background-color: red;
            opacity: .7;
        }
        .top{
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            padding: 20px;
            gap: 10px;
            margin-top: -150px;
            max-width: 900px;
            img{
                width: 50%;
                height: 250px;
                border-radius: 30px;
                object-fit: cover;
            }
            .texte{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: -30px;
                h2{
                    color: white;
                    font-size: 35px;
                    font-family: 'Montserrat', sans-serif;
                    @include tablet{
                        font-size: 25px;
                    }
                }
                span{
                    color: white;
                    font-size: 14px;
                    text-align: center;
                    font-family: 'Gilda Display', serif;
                    font-weight: bold;
                    max-width: 400px;
                    @include tablet{
                        font-size: 12px;
                    }
                }
            }
        }
        .bottom{
            display: flex;
            flex-direction: column;
            margin-top: 250px;
            position: absolute;
            width: 100%;
            text-align: center;
            max-width: 900px;
            h2{
                color: white;
                font-size: 25px;
                font-family: 'Gilda Display', serif;
                font-weight: bold;
            }
            .content{
                display: flex;
                gap: 50px;
                width: 80%;
                height: 50px;
                margin-left: auto;
                margin-right: auto;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                img{
                    width: 40px;

                }
            }
        }
    }
    .fourth-block{
        background-image: url('../../assets/images/jcb.avif');
        background-size: cover;
        background-repeat: none;
        background-position: center;
        background-color: #18191C;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 450px;
        .block-blur{
            position: absolute;
            width: 100%;
            height: 450px;
            background-color: red;
            opacity: .5;
        }
        .top{
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            padding: 20px;
            gap: 10px;
            margin-top: -150px;
            max-width: 900px;
            img{
                width: 50%;
                height: 250px;
                border-radius: 30px;
                object-fit: cover;
            }
            .texte{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: -30px;
                h2{
                    color: white;
                    font-size: 35px;
                    font-family: 'Montserrat', sans-serif;
                    @include tablet{
                        font-size: 25px;
                    }
                }
                span{
                    color: white;
                    font-size: 14px;
                    text-align: center;
                    font-family: 'Gilda Display', serif;
                    font-weight: bold;
                    max-width: 400px;
                    @include tablet{
                        font-size: 12px;
                    }
                }
            }
        }
        .bottom{
            display: flex;
            flex-direction: column;
            margin-top: 250px;
            position: absolute;
            width: 100%;
            text-align: center;
            max-width: 900px;
            h2{
                color: white;
                font-size: 25px;
                font-family: 'Gilda Display', serif;
                font-weight: bold;
            }
            .content{
                display: flex;
                gap: 50px;
                width: 80%;
                height: 50px;
                margin-left: auto;
                margin-right: auto;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                img{
                    width: 40px;

                }
            }
        }
    }
}