.blog-container1234 {
  width: 700px;
  margin: 0 auto;
  font-weight: 400;
  padding-top: 170px;
  @media (max-width: 900px) {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .titleArticle {
    font-size: 42px;
    font-weight: 700;
    font-style: normal;
    margin-bottom: 32px;
    margin-top: 0;
    color: white;
  }

  .subtitle {
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    margin-bottom: -0.28em;
    margin-top: 1.95em;
    color: white;
  }

  .type-block {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 150;
    font-style: normal;
    margin-top: 0.94em;
    color: white;
    a{
      color: white;
      font-weight: bold;
    }
  }

  .intro {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 150;
    font-style: normal;
    margin-top: 0.94em;
    color: white;
    a{
      color: white;
      font-weight: bold;
    }
  }

  .author {
    font-size: inherit;
    font-family: inherit;
    font-weight: 400;
    color: white;
  }

  .date1 {
    font-size: inherit;
    font-family: inherit;
    font-weight: 400;
    color: white;
  }

  .image-container {
    .centered-image {
      //max-width: 700px;
      width: 700px;
      height: 500px;
      margin: 0 auto;
      border-radius: 2px;
      color: white;
      margin-top: 20px;
      @media (max-width: 900px) {
        width: 100%;
        height: 100%;
      }
    }
    .image-description {
      color: white;
      text-align: center;
      p{
        font-weight: normal;
        font-size: 15px;
      }
      a{
        color: white;
        font-weight: bold;
      }
    }
  }




}

.similar-articles1234 {
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  margin-bottom: 20px;
  @media (max-width: 900px) {
    margin-left: 30px;
    margin-right: 30px;
  }

  .articles-container {
    justify-content: space-between;
    margin-left: 150px;
    margin-right: 150px;
    @media (max-width: 700px) {
      margin-left: 0;
      margin-right: 0;
    }

    .book {
      margin-top: 30px;
      margin-bottom: 30px;
      width: 95%;
      @media (max-width: 900px) {
        margin-bottom: 40px;
        width: 100%;
      }

      .image-title {
        margin-top: 30px;
      }
    }
  }



}
.video-responsive {
  margin-top: 15px;
  position: relative;
  height: 380px;
}

.video-responsive iframe {

  height: 100%;
  width: 100%;
}