@import '../../style.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');

.Slide{
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    .leftSide{
        position: fixed;
        background-color: #181818;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100vh;
        width: 20%;
        padding-top: 50px;
        .logout{
            position: relative;
            top: -10px;
            button{
                background-color: #ff1616;
                color: white;
                border: none;
                border-radius: 20px;
                width: 150px;
                height: 40px;
            }
        }
        .top{
            display: flex;
            align-items: center;
            height: 80px;
            justify-content: center;
            align-items: center;
            margin-bottom: 50px;
            @include tablet{
                margin-bottom: 0px;
            }
            img{
                border: 5px solid red;
                width: 80px;
                height: 80px;
                border-radius: 50%;
            }
        }
        hr{
            display: none;
        }
        .leftSideCloseIcon{
            display: none;
        }
        @include tablet{
            width: 100%;
            background-color: rgb(18, 18, 18);
            z-index: 100;
            padding-top: 10px;
            .leftSideCloseIcon{
                display: flex;
                position: absolute;
                color: red;
                font-size: 40px;
                left: 0;
            }
            hr{
                display: flex;
                width: 100vw;
                height: 2px;
                background-color: white;
                position: relative;
            }
        }
        .bottom{
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;
            justify-content: center;
            align-items: flex-start;
            .box{
                display: flex;
                gap: 10px;
                text-align: start;
                img{
                    width: 20px;
                    height: 20px;
                    color: white;
                }
                .icon{
                    color: #5f6065;
                }
                span{
                    // color: white;
                    font-family: 'Montserrat', sans-serif;
                }
            }
            .box.active{
                color:#ff1616;
            }
            .box.inactive{
                color: white;
                .boxIcon{
                    color: #5f6065;
                }
            }
        }
    }
    @include tablet{
        .leftSide.active{
            left: 0%;
            transition: 0.5s;
        }
        .leftSide.inactive{
            left: -100%;
            transition: 0.5s;
        }
    }
    .navbar-content{
        position: relative;
        display: flex;
        flex-direction: column;
        left: 22%;
        width: 76%;
        padding-top: 50px;
        gap: 50px;
        @include tablet{
            left: 0%;
            margin-left: auto;
            margin-right: auto;
            width: 90%;
        }
        .navbar{
            position: relative;
            background-color: #181818;
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 70px;
            border-radius: 20px;
            padding: 10px;
            .navbarIcon{
                display: none;
            }
            @include tablet{
                position: fixed;
                width: 90%;
                .navbarIcon{
                    display: flex;
                    gap: 20px;
                }
            }
            .addMusicPc{
                @include tablet{
                    display: none;
                }
                background-color:#ff1616;
                padding:10px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                gap: 30px;
                position: relative;
                // width: 25%;
                color: white;
                text-decoration: none;
                font-family: 'Montserrat', sans-serif;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .content{
                background-color: black;
                padding:10px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                gap: 20px;
                position: relative;
                width: 30%;
                justify-content: flex-end;
                @include tablet{
                    width: 70%;
                    gap: 10px;
                }
                input{
                    height: 30px;
                    background-color:#5f6065;
                    border: none;
                    border-radius: 20px;
                    padding: 10px;
                    outline: none;
                    color: white;
                    width: 80%;
                    @include tablet{
                        // width: 150px;
                        width: 80%;
                    }
                    &::placeholder {
                        color: #c2c2c2;
                        position: relative;
                    }
                }
                img{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }
                .rightSideNavbarContentIcon{
                    display: none;
                    @include tablet{
                        display: flex;
                        color: red;
                        font-size: 25px;
                    }
                }
            }
        }
    }
    .addMusicTel.active{
        display: none;
        @include tablet{
            display: flex;
            transition: transform .5s;
            height: 70px;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            transform: scaleX(1);
            margin-top: 100px;
            .button{
                width: 100%;
                color: white;
                border: none;
                border-radius: 20px;
                background-color: #ff1616;
                font-family: 'Montserrat', sans-serif;
                font-size: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
            }
        }
    }
    .addMusicTel.inactive{
        display: none;
        @include tablet{
            display: flex;
            position: absolute;
            top: -10%;
            transform: scaleX(0);
        }
    }
}