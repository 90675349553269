@import "../../style.scss";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

@media only screen and (min-width: 1120px){
@include themify($themes) {
  .login{

    .div-main {
        background-size: cover;
        background-position: center;
        background-image: url('/assets/portrait-of-woman-singing-on-microphone.jpg');
        display: flex;
    }

    .div-main21 {
        font-Family: 'Lato, sans-serif';
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 700px;
        width: 100%;
        // background-color: rgba(200, 200, 200, 0.5);

        .top-div {
            display: flex;
            justify-content: space-between;

            background-color: rgba(34, 32, 32, 0.8);
            width: 70%;
            height: 100px;

            .text {
                padding: 10px;
                //border: 1px solid #ccc;
                color: white;
                font-size: 20px;
            }

            .left {
                //  justify-items: left;
                display: flex;
                flex-direction: column;
                padding-left: 45px;
                //align-items: flex-start;
            }

            .right {
                // justify-items: left;
                display: flex;
                flex-direction: column;
                padding-right: 45px;
                // align-items: flex-end;
            }
        }

        .centered-div {
            display: flex;
            //flex-direction: column;
            width: 70%;
            align-items: center;
            margin-top: 20px;

            .form-container {
                background-color: rgba(34, 32, 32, 0.8);
                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 30px;
                width: 50%;
                padding-right: 35px;
                .error{
                  text-align: center;
                  color: red;
                }
                input {
                    background-color: rgba(220, 220, 220, 0.7);
                    // padding-right: 10px;
                    margin-bottom: 20px;
                    margin-top: 20px;
                    border: none;
                    border-radius: 4px;
                    width: 100%;
                    height: 45px;
                    font-size: 20px;
                    color: #ffffff;
                    outline: none;
                    &::placeholder {
                        color: #ffffff;
                        font-size: 20px;
                    }
                }

                .butom {
                    display: flex;
                    justify-content: space-between;

                    .button-login {
                        background-color: rgb(255, 0, 0);
                        border: none;
                        font-size: 20px;
                        font-weight: bold;
                        color: #ffffff;
                        height: 45px;
                        border-radius: 12px;
                    }

                    span {

                        color: #fff;
                    }
                }



                .logo-icon {
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                }

                .logo-icon {
                    width: 24px;
                    height: 24px;
                    margin-right: 10px;
                }

                .button-text {
                    vertical-align: middle;
                }

                .provider-button {
                    display: flex;
                    align-items: center;
                    background-color: rgba(220, 220, 220, 0.7);
                    // padding-right: 10px;
                    margin-bottom: 45px;
                    margin-top: 4s5px;
                    border: none;
                    border-radius: 4px;
                    width: 100%;
                    height: 45px;
                    font-size: 20px;
                    color: #fff;

                    &::placeholder {
                        color: #fff;
                        font-size: 20px;
                    }
                }
                .no-account{
                  color: #fff;
                  .button{
                    color: red;
                  }
                }
            }
        }

        .red-pi-check {
            color: red;
        }

    }
  }
}
}

@media only screen and (min-width: 981px) and (max-width: 1119px) {
    @include themify($themes) {
      .login{
        .div-main {
          background-size: cover;
          background-position: center;
          background-image: url('/assets/portrait-of-woman-singing-on-microphone.jpg');
          display: flex;
          height: 700px;
        }
    
        .div-main21 {
          font-family: 'Lato', sans-serif;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          // background-color: rgba(200, 200, 200, 0.5);
    
          .top-div {
            display: flex;
            justify-content: space-between;
            background-color: rgba(34, 32, 32, 0.8);
            width: 60%;
            min-width: 700px;
            padding: 10px;
            height: 100px;
    
            .text {
              padding: 10px;
              color: white;
              font-size: 16px;
            }
    
            .left {
              display: flex;
              flex-direction: column;
              padding-left: 30px;
            }
    
            .right {
              display: flex;
              flex-direction: column;
              padding-right: 30px;
            }
          }
    
          .centered-div {
            display: flex;
            width: 60%;
            align-items: center;
            margin-top: 20px;
            min-width: 700px;
            // height: 800px;
            .form-container {
              background-color: rgba(34, 32, 32, 0.8);
              padding-top: 20px;
              padding-bottom: 20px;
              padding-left: 20px;
              width: 80%;
              padding-right: 20px;
    
              input {
                background-color: rgba(220, 220, 220, 0.7);
                margin-bottom: 10px;
                margin-top: 10px;
                border: none;
                border-radius: 4px;
                width: 100%;
                height: 35px;
                font-size: 14px;
                color: #ffffff;
                outline: none;
                &::placeholder {
                  color: #ffffff;
                  font-size: 14px;
                }
              }
    
              .butom {
                display: flex;
                justify-content: space-between;
    
                .button-login {
                  background-color: rgb(255, 0, 0);
                  border: none;
                  font-size: 16px;
                  font-weight: bold;
                  color: #ffffff;
                }
    
                span {
                  color: #fff;
                }
              }
    
              .logo-icon {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
              }
    
              .logo-icon {
                width: 20px;
                height: 20px;
                margin-right: 5px;
              }
    
              .button-text {
                vertical-align: middle;
              }
    
              .provider-button {
                display: flex;
                align-items: center;
                background-color: rgba(220, 220, 220, 0.7);
                margin-bottom: 20px;
                margin-top: 20px;
                border: none;
                border-radius: 4px;
                width: 100%;
                height: 35px;
                font-size: 14px;
                color: #fff;
    
                &::placeholder {
                  color: #fff;
                  font-size: 14px;
                }
              }
              .no-account{
                position: relative;
                top: 10px;
                font-size: 12px;
                color: white;
                font-family: 'Open Sans', sans-serif;
                .button{
                  color: red;
                }
              }
            }
          }
    
          .red-pi-check {
            color: red;
          }
        }

      }
    }
  }

  @media only screen and (max-width: 980px) {
    @include themify($themes) {
      .login{
        .div-main {
          background-size: cover;
          background-position: center;
          background-image: url('/assets/portrait-of-woman-singing-on-microphone.jpg');
          display: flex;
          justify-content: center;
          align-items: center;
        }
        
        .div-main21 {
          font-family: 'Lato', sans-serif;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 150px;
          height: 600px;
          width: 100%;
          max-width: 500px;
          // background-color: rgba(200, 200, 200, 0.5);
    
          .top-div {
            display: none;
           /*  display: flex;
            flex-wrap: wrap;
            justify-content: center;
            background-color: rgba(34, 32, 32, 0.7);
            width: 80%;
            padding: 10px;
    
            .text {
              padding: 10px;
              color: white;
              font-size: 14px;
            }
    
            .left {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 10px;
            }
    
            .right {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 10px;
            } */
          }
    
          .centered-div {
            display: flex;
            flex-direction: column;
            width: 90%;
            align-items: center;
            margin-top: 20px;
            height: 800px;
            .form-container {
              background-color: rgba(34, 32, 32, 0.8);
              padding: 20px;
              width: 100%;
    
              input {
                background-color: rgba(220, 220, 220, 0.7);
                margin-bottom: 10px;
                margin-top: 10px;
                border: none;
                border-radius: 4px;
                width: 100%;
                height: 45px;
                font-size: 14px;
                color: #ffffff;
                outline: none;
    
                &::placeholder {
                  color: #ffffff;
                  font-size: 14px;
                }
              }
              .error{
                color: red;
              }
    
              .butom {
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                .button-login {
                  background-color: rgb(255, 0, 0);
                  border: none;
                  font-size: 16px;
                  font-weight: bold;
                  color: #ffffff;
                  border-radius: 4px;
                  height: 45px;
                }
    
                span {
                  color: #fff;
                  font-size: 12px;
                  position: relative;
                  right: 5px;
                  text-decoration: underline;
                }
              }
    
              .logo-icon {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
              }
    
              .logo-icon {
                width: 20px;
                height: 20px;
                margin-right: 5px;
              }
    
              .button-text {
                vertical-align: middle;
              }
    
              .provider-button {
                display: flex;
                align-items: center;
                background-color: rgba(220, 220, 220, 0.7);
                margin-bottom: 20px;
                margin-top: 20px;
                border: none;
                border-radius: 4px;
                width: 100%;
                height: 35px;
                font-size: 14px;
                color: #fff;
    
                &::placeholder {
                  color: #fff;
                  font-size: 14px;
                }
              }
              .no-account{
                font-size: 10px;
                color: white;
                position: relative;
                top: 10px;
                .button{
                  background-color: transparent;
                  border: none;
                  color: red;
                  font-weight: bold;
                }
              }
            }
          }
    
          .red-pi-check {
            color: red;
          }
        }
      }
    }
  }
  