@import "../../style.scss";

.manageData{
    background-color: black;
    height: 100vh;
    color: white;
    
    .attente{
        position: relative;
        display: flex;
        flex-direction: column;
        left: 22%;
        width: 76%;
        padding-top: 75px;
        align-items: center;
        justify-content: center;
        height: 50px;
        border-radius: 20px;
        gap: 50px;
        span{
            font-size: 20px;
            color: rgb(0, 255, 0, .5);
            text-align: center;
        }
        .loader-container{
            display: flex;
            .dot{
                position: relative;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: white;
                margin: 0 5px;
                animation: loader infinite 0.3s alternate;
            }
            .d2{
                animation-delay: 0.05s;
            }
            .d3{
                animation-delay: 0.1s;
            }
        }
        @keyframes loader{
            100%{
                transform: translateY(-30px);
            }
        }
    }
    .rightSide{
        position: relative;
        display: flex;
        flex-direction: column;
        left: 22%;
        width: 76%;
        padding-top: 50px;
        gap: 50px;
        margin-top: 100px;
        @include tablet{
            left: 0%;
            margin-left: auto;
            margin-right: auto;
            width: 90%;
        }
        .cov{
            display: flex;
            gap: 30px;
            flex-direction: column;
            color: white;
            border: 2px white solid;
            width: 50%;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            @include mobile{
                flex-direction: column;
                width: 100%;
            }
            @include tablet{
                flex-direction: column;
                width: 100%;
            }
            p{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
        button{
            width: 200px;
            margin-left: auto;
            margin-right: auto;
            background-color: red;
            border-radius: 10px;
            height: 50px;
            border: none;
            color: white;
        }
    }
}