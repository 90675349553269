@import '../../style.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

@media only screen and (min-width: 981px){
  .footer{
    background-color: #18191C;
    position: relative;
    color: white;
    font-Family: 'Lato, sans-serif';
    font-size: 20px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 520px;
    .content {
        display: flex;
        gap: 20px;
        padding: 50px;
        .footer-bottom{
          display: flex;
          width: 40%;
          margin-left: auto;
          margin-right: auto;
          justify-content: space-between;
          align-items: center;
          text-align: start;
          .footer-box{
            display: flex;
            width: 100%;
            .footer_column{
              display: flex;
              flex-direction: column;
              height: 250px;
              width: 33%;
              h4 {
                margin-bottom: 10px;
                font-family: 'Open Sans', sans-serif;
                font-size: 20px;
                height: 50px;
              }
              ul {
                list-style-type: none;
                padding: 0;
                height: 120px;
                .link{
                  text-decoration: none;
                  color: white;
                  font-size: 16px;
                  display: flex;
                  gap: 5px;
                  align-content: center;
                  .icon{
                    font-size: 16px;
                    position: relative;
                    top: 3px;
                  }
                }
        
                li {
                    margin-bottom: 5px;
                }
              }
            }
          }
        }
        .spotify-widget{
          width: 60%;
          iframe {
            width: 100%;
            padding-top: 30px;
            height: 400px;
          }
        }
    }
    .copyright{
      background-color: black;
      width: 100%;
      text-align: center;
    }

   
  }

}
@media only screen and (max-width: 980px) {
  .footer {
    display: flex;
    flex-direction: column;
    background-color: #18191C;
    color: white;
    font-size: 20px;
    font-family: 'Lato', sans-serif;
    height: 560px;
    .spotify-widget{
      border: none;
      iframe{
        height: 352px;
      }
    }
    .copyright{
      background-color: black;
      width: 100%;
      text-align: center;
      font-size: 10px;
    }
  }
  .footer-box{
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    text-align: start;
    gap: 20px;
    .footer_column{
      width: 50%;
      h4 {
        margin-bottom: 10px;
        height: 50px;
        font-size: 20px;
      }
      ul {
        list-style-type: none;
        padding: 0;
        font-size: 12px;
        .link{
          text-decoration: none;
          color: white;
          font-size: 12px;
          display: flex;
          gap: 5px;
          align-content: center;
          .icon{
            font-size: 12px;
            position: relative;
            top: 3px;
          }
        }
      }
    }
  }
}
