@import '../../style.scss';
.allTransactions{
    border-bottom: 1px solid #5f6065;
    padding-bottom: 10px;
    .box{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        color: #5f6065;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        cursor: pointer;
        .more{
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            left: -30px;
            text-align: center;
            color: white;
            transition: 5s;
            animation-name: widthAnimation;
            animation-duration: .2s;
            animation-timing-function: ease;
            animation-fill-mode: forwards;
            height: 0px;
            overflow: hidden;
            width: 100px;
            button{
                background-color: #ff1616;
                border: none;
                border-radius: 10px;
                width: 100px;
                color: white;
                font-family: 'Montserrat', sans-serif;
            }
            button.enCours{
                background-color: yellow;
                color: #c2c2c2;
            }
            button.publie{
                background-color: green;
            }
        }
        @keyframes widthAnimation {
            0% {
              height: 0;
            }
            50% {
              height: 20;
            }
            100% {
              height: 65px;
            }
          }
        @include tablet{
            font-size: 12px;
        }
        .titre{
            width: 30%;
        }
        .id{
            width: 14%;
        }
        .montant{
            width: 14%;
    
        }
        .methodes{
            width: 14%;
        }
        .status{
            width: 14%;
            font-size: 16px;
            font-family: 'Montserrat', sans-serif;
            display: flex;
            align-items: center;
            gap: 5px;
            @include tablet{
                font-size: 12px;
            }
            .statusDoIcon{
                width: 15px;
                height: 15px;
                background-color: green;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                .icon{
                    font-size: 14px;
                    color: white;
                }
            }
            .statusNoIcon{
                width: 15px;
                height: 15px;
                background-color: white;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                .icon{
                    font-size: 14px;
                    color:#ff1616;
                }
            }
            .statusInIcon{
                width: 15px;
                height: 15px;
                background-color: yellow;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                .icon{
                    font-size: 14px;
                    color: black;
                }
            }
        }
        .options{
            width: 14%;
        }
    
    }
    .allTransactionsMore{
        width: 100%;
        color: white;
        margin-top: 20px;
        table{
            width: 100%;
            margin-right: auto;
            margin-left: auto;
            tbody{
                tr{
                    td{
                        display: flex;
                        justify-content: space-between;
                        button{
                            background-color: #ff1616;
                            border: none;
                            border-radius: 10px;
                            width: 100px;
                            color: white;
                            font-family: 'Montserrat', sans-serif;
                        }
                        button.enCours{
                            background-color: yellow;
                            color: #c2c2c2;
                        }
                        button.publie{
                            background-color: green;
                        }
                    }
                }
            }
        }
    }
}