@import "../../style.scss";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap");

body {
    background-color: black;
}

.mainDetail {
    font-family: "Lato", sans-serif;
    color: #ffffff;
    width: 100%;
    border-radius: 0px;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    background-color: #18191c;
    display: flex;
    flex-direction: column;
    align-items: center;

.detail{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #1f2125;
    width: auto;
    height: auto;
    max-width: 90%;
    //max-height: 100%;
    margin-top: 5%;
    padding-top: 10%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 10%;
    border-radius: 35px;
    font-size: 20px;
    .h22 {
        display: flex;
        align-items: start;
        text-align: left;
        justify-content: left;
        color: white;
        padding-top:25px;
        padding-bottom:25px;

    }
}
.ima2{
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #000;
    margin-top: 60px;
    margin-bottom: 50px;

    img {
        background: red;
        width:90%;
        padding-left: 20px;
        height: 300px;
        margin-right: 40px;
        object-fit: cover;
        border-radius: 15px;
        margin-bottom: 10px;
    }




    }

    @media (max-width: 980px) {

        .detail{
            margin-bottom: 50%;

        .ima2 {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .ima2 img {
           margin-bottom: 10px;
            img {
                background: red;
                padding-left: 20px;
                margin-right: 40px;
                object-fit: cover;
                border-radius: 15px;
                margin-bottom: 10px;
            }
        }
    }}

    figure {
        text-align: center;
        height: 100%;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    figcaption {
        font-size: 16px;
        color: #ffffff;
    }




}
