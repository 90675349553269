.generate-cover{
    position: relative;
    margin-top: 10px;
    font-size: 16px;
}
.generate-cover-contain{
    position: fixed;
    top: 50%;
    left: 50%;
    height: 200px;
    width: 400px;
    background-color: white;
    transform: translate(-50%, -50%);
    color:#000000;
    text-align: center;
    padding: 20px;
    border-radius: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}