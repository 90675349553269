@import "../../style.scss";
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');
@include themify($themes){
        .PolitiqueConfidentialites{
            background-color: #18191C;
            color: white;
            .corps{
                position: relative;
                padding-top: 200px;
                width: 80%;
                max-width: 1030px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 100px;
                .title{
                    
                    span{
                        font-family: 'copperplatebold';
                        font-size: 32px;
                        color: #3164F4;
                    }
                    p{
                        width: 70%;
                        font-family: 'Oswald', sans-serif;
                    }
                }
                .body{
                    position: relative;
                    // padding-top: 200px;
                    width: 100%;
                    max-width: 1030px;
                    span{
                        font-family: 'copperplatebold';
                        font-size: 16px;
                    }
                    p{
                        width: 100%;
                        font-family: 'Oswald', sans-serif;
                        text-align: justify;
                    }
                    .fiber{
                        position: relative;
                        width: 70%;
                        left: 50px;
                        padding-bottom: 20px;
                        display: flex;
                        font-family: 'Oswald', sans-serif;
                        .icon{
                            font-size: 10px;
                            position: relative;
                            top: 10px;
                        }
                    }
                    button{
                        font-family: 'Roboto Slab', serif;
                        width: 150px;
                        height: 50px;
                        background-color: #3164F4;
                        border: none;
                        border-radius: 12px;
                        color: white;
                        cursor: pointer;
                        font-size: 16px;
                    }
                }
        }
    }
}