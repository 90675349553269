@import "../../style.scss";

.artistes{
    margin: 0;
    background-color: black;
    width: 100%;
    // height: 100vh;
    min-height: 100vh;
    color: white;
    .artistes-data{
        position: relative;
        margin-top: 50px;
        color: white;
        left: 22%;
        width: 76%;
        @include tablet{
            position: relative;
            left: 0%;
            margin-left: auto;
            margin-right: auto;
            width: 90%;
            margin-top: 100px;
        }
        .artistes-data-Header{
            display: flex;
            width: 100%;
            @include tablet {
                font-size: 10px;
            }
            div{
                display: flex;
                width: 15%;
                border: 1px solid #5f6065;
                position: relative;
                padding-left: 10px;
                overflow-x: scroll;
                &.email{
                    width: 25%;
                }
            }
            div::-webkit-scrollbar {
                width: 1px;
                height: 1px;
            }
            div::-webkit-scrollbar-track {
                background-color: transparent;
            }
              
            div::-webkit-scrollbar-thumb {
                background-color: #5f6065;
                border-radius: 4px;
            }
              
            div::-webkit-scrollbar-thumb:hover {
            background-color: #555;
            }
        }
    }
    .copyright{
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: white;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        text-align: center;
    }
}