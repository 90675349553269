@import "../../style.scss";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
.services-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: fit-content;
  height: 100%;
  font-family: "Lato", sans-serif;
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 80px;
  @media (min-width: 1200px) {
    padding-top: 40px; 
  }
  width: 100%;
  overflow: hidden;
  height: fit-content;
  justify-content: center;
  align-items: center;
}

.background-image {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: -10%;
}

.background-image:nth-child(1) {
  background-image: url("../../assets/slider/ground1.jpg");
  padding-top: 100px;
  position: relative;
}

.background-image:nth-child(2) {
  background-image: url("../../assets/slider/ground2.png");
  padding-top: 10%;
  @media (min-width: 735px) {
    background-size: 103vw auto;
  }
}

.background-image:nth-child(3) {
  background-image: url("../../assets/slider/ground3.jpg");
  padding-top: 10%;
  padding-bottom: 15%;
}

.distribution {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  //padding: 20px;
  padding-left: 5%;
  padding-right: 5%;
  width: 80%;
  margin: 0 auto;
  border-radius: 12px;
  margin-bottom: 5%;
  margin-top: 100px;
  height: 200px;
  @include tablet{
    width: 98%;
    margin-top: 0px;
  }
}

.promotion {
  background-color: #ff0000;
  display: flex;
  color: #ffffff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  padding-left: 5%;
  padding-right: 5%;
  width: 80%;
  margin: 0 auto;
  border-radius: 12px;
  margin-bottom: 25px;
  @include tablet{
    width: 98%;
  }

}

.droit {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  padding-left: 5%;
  padding-right: 5%;
  width: 80%;
  margin: 0 auto;
  border-radius: 12px;
  @include tablet{
    width: 98%;
  }
}

.slider {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  align-items: center;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  @include tablet{
    width: 98%;
  }
  .slider-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 20px;
  }
}

.slider-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 300px;
  width: 250px;
  border-radius: 12px;
  @include tablet{
    width: 48%;
    height: 250px;
  }
}

.titre-slide {
  color: red;
  text-transform: uppercase;
}

.slider-image {
  object-fit: cover;
  width: 250px;
  border-radius: 12px 12px 0px 0px;
  height: 250px;
  @include tablet{
    width: 100%;
    height: 200px;
  }
}

.slider-text {
  margin-top: 0px;
  font-size: 25px;
  color: #fff;
  font-weight: bold;
  display: flex;
  width: 100%;
  height: 50px;
  background-color: black;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 12px 12px;
  padding: 10px;
  .info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #ff0000;
    height: 100%;
    .music-title{
      font-size: 20px;
      @include tablet{
        font-size: 14px;
      }
    }
    .music-title.pays{
      font-size: 14px;
      @include tablet{
        font-size: 10px;
      }
    }
    .artiste-name{
      font-size: 12px;
      color: gray;
      @include tablet{
        font-size: 10px;
      }
    }
  }
  .listen{
    display: flex;
    gap: 5px;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    span{
      color: gray;
    }
    .icon{
      color: red;
    }
  }
  @include tablet{
    font-size: 12px;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  width: 82%;
  padding-top: 2%;
  @include tablet{
    width: 100%;
  }
}

.top-div {
  margin-bottom: 10px;
  background-color: yellow;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.bottom-div {
  background-color: yellow;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 12px;
}

iframe {
  height: 250px;
}
.spotify-widget {
  width: 100%;
}

// @media only screen and (max-width: 1120px) {
//   .slider-text {
//     margin-top: 0px;
//     font-size: 15px;
//     color: #fff;
//     font-weight: bold;
//   }
//   .slider-image{
//     object-fit: contain;
//     width: 100%;
//     height: 180px;
//     margin-bottom: 0px;
//     border-radius: 12px;
//   }

//   .slider {
//     width: 80%;
//     margin: 0 auto;
//     text-align: center;
//     align-items: center;
//     margin-bottom: 20px;
//   }

//   .slider-slide {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     text-align: center;
//     padding-left: 10px;
//     padding-right: 10px;
//     width: 80%;
//   }
//   .container {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     width: 100%;
//   }

//   .left-div {
//     width: 80%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }

//   .right-div {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     font-size: 25px;
//     font-weight: bold;
//     margin-bottom: 20px;
//   }

//   .top-div {
//     background-color: yellow;
//     width: 100%;
//     height: 50px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 12px;
//   }
//   .bottom-div {
//     background-color: yellow;
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 50px;
//     border-radius: 12px;
//   }
//   iframe {
//     height: 180px;
//   }
// }
