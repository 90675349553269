@import '../../style.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');

@include themify($themes){
    .sendmail{
        width: 100%;
        .sendmail-content{
            position: relative;
            left: 22%;
            width: 76%;
            padding-top: 50px;
            display: flex;
            gap: 40px;
            @include tablet{
                flex-direction: column;
                left: 0%;
                margin-left: auto;
                margin-right: auto;
                width: 90%;
                margin-top: 50px;
            }
            hr{
                height: 60vh;
                background-color: white;
                width: 2px;
                @include tablet{
                    display: none;
                }
            }
            .sendmail-content-left{
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 10px;
                @include tablet{
                    width: 100%;
                }
                button{
                    width: 200px;
                    background-color: red;
                    border-radius: 10px;
                    height: 50px;
                    border: none;
                    color: white;
                }
                label{
                    display: flex;
                    flex-direction: column;
                    input{
                        width: 100%;
                        padding: 12px 16px;
                        border-radius: 8px;
                        color: #fff;
                        height: 40px;
                        border: 1px solid #414141;
                        background-color: transparent;
                        font-family: inherit;
                    }
                    textarea{
                        width: 100%;
                        padding: 12px 16px;
                        border-radius: 8px;
                        resize: none;
                        color: #fff;
                        height: 300px;
                        border: 1px solid #414141;
                        background-color: transparent;
                        font-family: inherit;
                    }
                }
            }
            .sendmail-content-leftRight{
                display: flex;
                flex-direction: column;
                width: 50%;
                height: 70vh;
                gap: 40px;
                overflow-y: scroll;
                overflow-x: hidden;
                @include tablet{
                    width: 100%;
                }
                .sendmail-content-leftRight-box{
                    display: flex;
                    flex-direction: column;
                    width: 80%;
                    gap: 20px;
                    background-color: rgb(255, 0, 0);
                    border-radius: 20px;
                    padding: 20px;
                    @include tablet{
                        width: 100%;
                    }
                    .sujet{
                        color: white;
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        .date{
                            color: #414141;
                            font-size: 8px;
                        }
                    }
                    .content{
                        color: black;
                    }
                }
            }
            .sendmail-content-leftRight::-webkit-scrollbar {
                width: 4px;
            }
            .sendmail-content-leftRight::-webkit-scrollbar-track {
                background-color: transparent;
            }
              
            .sendmail-content-leftRight::-webkit-scrollbar-thumb {
                background-color: #ff1616;
                border-radius: 4px;
            }
              
            .sendmail-content-leftRight:-webkit-scrollbar-thumb:hover {
            background-color: #555;
            }
        }
    }
}