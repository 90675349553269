@import "../../style.scss";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap");



.book {
  position: relative;
  border-radius: 10px;
  width: 450px;
  height: 400px;
  background-color: whitesmoke;
  -webkit-box-shadow: 1px 1px 12px #000;
  box-shadow: 1px 1px 12px #000;
  -webkit-transform: preserve-3d;
  -ms-transform: preserve-3d;
  transform: preserve-3d;
  -webkit-perspective: 2000px;
  perspective: 2000px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #000;
  margin-top: 60px;

  @media (max-width: 1825px) and (min-width: 1700px) {
    width: 400px;
    height: 300px;
  }

  @media (max-width: 1700px) and (min-width: 1500px) {
    width: 350px;
    height: 300px;
  }

  @media (max-width: 1500px) and (min-width: 1370px) {
    width: 300px;
    height: 250px;
  }

  @media (max-width: 1370px) and (min-width: 1250px) {
    width: 450px;
    height: 350px;
  }

  @media (max-width: 1250px) and (min-width: 1035px) {
    width: 350px;
    height: 350px;
  }

  @media (max-width: 1035px) and (min-width: 950px) {
    width: 300px;
    height: 250px;
  }

  @media (max-width: 950px) {
    width: 90%;
    margin-bottom: 10%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }

  .image-title {
    position: absolute;
    bottom: -50px;
    left: 5px;
    color: red;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    background-color: #181717;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 15px;
    @include tablet{
      font-size: 14px;
    }
  }

  .content {
    text-align: center;
    align-items: center;
  }

  .content {
    margin-left: 50px;
    margin-right: 10px;
  }
}

.cover {
  top: 0;
  position: absolute;
  background-color: lightgray;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s;
  transform-origin: 0;
  box-shadow: 1px 1px 12px #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.book:hover .cover {
  transition: all 0.5s;
  transform: rotatey(-80deg);
}

// p {
//   font-size: 20px;
//   font-weight: bolder;
// }


.limited-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.full-content {
  padding-right: 100px;
}


.dots {
  color: rgb(89, 1, 92);
}

.show-more {
  background-color: rgb(110, 110, 228);
  margin-left: 40px;
}

.button1 {
  border-radius: 30px;
  border: none;
  cursor: pointer;
  outline: none;
  width: 150px;
  height: 54px;
  background-image: linear-gradient(to top, #222 0%, #333 80%, #444 100%);
  color: white;
  font-size: 20px;
  font-weight: 400;
  font-family: monospace;
  transition: all 0.3s;
  text-shadow: 0 4px 3px black;
  margin-top: 30px;
}

.button1:hover {
  box-shadow: 0 4px 3px 1px #333, 0 6px 8px #222, 0 -4px 4px #222,
  0 -6px 4px #333, inset 0 0 3px 3px black;
}

.button1:active {
  box-shadow: 0 4px 3px 1px #333, 0 6px 8px #222, 0 -4px 4px #222,
  0 -6px 4px #333, inset 0 0 3px 3px black;
}

.button1:focus {
  box-shadow: 0 4px 3px 1px #333, 0 6px 8px #222, 0 -4px 4px #222,
  0 -6px 4px #333, inset 0 5px 7px 0 black;
}




.ReactModal__Content {
  font-family: sans-serif;
  color: #000;
  font-size: 20px;
  border-radius: 30px;
  width: auto;
  height: auto;
  max-width: 85%;
  max-height: 85%;
  //margin: auto;
  overflow: auto;
  background-color: whitesmoke;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  padding: 50px;
  margin-top: 95px;

  .grandPoint{
    font-family: "Lato", sans-serif;
  }

  @media (max-width: 1300px) {
    width: 100%;
    height: 100%;
    padding-top: 70px;
  }

  @media (max-width: 525px) and (min-width: 430px){
    padding-top: 200px;
  }

  @media (max-width: 430px) and (min-width: 380px){
    padding-top: 400px;
  }

  @media (max-width: 380px) and (min-width: 335px){
    padding-top: 600px;
  }

  @media (max-width: 335px) {
    padding-top: 800px;
  }

  .button1 {
    border-radius: 30px;
    border: none;
    cursor: pointer;
    outline: none;
    width: 150px;
    height: 54px;
    background-image: linear-gradient(to top, #222 0%, #333 80%, #444 100%);
    color: white;
    font-size: 20px;
    font-weight: 400;
    font-family: monospace;
    transition: all 0.3s;
    text-shadow: 0 4px 3px black;
    margin-top: 30px;
  }

  .button1:hover {
    box-shadow: 0 4px 3px 1px #333, 0 6px 8px #222, 0 -4px 4px #222,
    0 -6px 4px #333, inset 0 0 3px 3px black;
  }

  .button1:active {
    box-shadow: 0 4px 3px 1px #333, 0 6px 8px #222, 0 -4px 4px #222,
    0 -6px 4px #333, inset 0 0 3px 3px black;
  }

  .button1:focus {
    box-shadow: 0 4px 3px 1px #333, 0 6px 8px #222, 0 -4px 4px #222,
    0 -6px 4px #333, inset 0 5px 7px 0 black;
  }

}
