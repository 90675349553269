@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');
@import '../../style.scss';

.dashboard{
    margin: 0;
    background-color: black;
    width: 100%;
    height: auto;
    min-height: 100vh;
    font-family: 'Lato', sans-serif;
    // text-transform: uppercase;
    .messageToVerified{
        position: relative;
        display: flex;
        flex-direction: column;
        left: 22%;
        width: 76%;
        padding-top: 50px;
        gap: 50px;
        text-align: center;
        color: white;
        @include tablet{
            left: 0%;
            margin-left: auto;
            margin-right: auto;
            width: 90%;
            margin-top: 100px;
        }
        button{
            width: 200px;
            margin-left: auto;
            margin-right: auto;
            background-color: red;
            border: none;
            height: 50px;
            border-radius: 20px;
            color: white;
        }
    }
    .messageToVerifiedDisable{
        position: relative;
        display: flex;
        flex-direction: column;
        left: 22%;
        width: 76%;
        padding-top: 50px;
        gap: 50px;
        text-align: center;
        color: white;
        @include tablet{
            left: 0%;
            margin-left: auto;
            margin-right: auto;
            width: 90%;
            margin-top: 100px;
        }
        button{
            width: 200px;
            margin-left: auto;
            margin-right: auto;
            background-color: red;
            border: none;
            height: 50px;
            border-radius: 20px;
            color: white;
            opacity: .3;
        }
    }
    .rightSide{
        position: relative;
        display: flex;
        flex-direction: column;
        left: 22%;
        width: 76%;
        padding-top: 50px;
        gap: 50px;
        @include tablet{
            left: 0%;
            margin-left: auto;
            margin-right: auto;
            width: 90%;
        }
        .chiffre{
            display: flex;
            width: 100%;
            gap: 30px;
            flex-direction: column;
            @include tablet{
                margin-top: 100px;
            }
            .content{
                display: flex;
                align-items: center;
                width: 100%;
                gap: 30px;
                @include tablet{
                    flex-direction: column;
                }
                .box{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    width: 100%;
                    height: 80px;
                    background-color: #181818;
                    border-radius: 20px;
                    padding: 20px;
                    @include tablet{
                        justify-content: center;
                    }
                    .boxContent{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        @include tablet{

                            width: 50%;
                        }
                        .iconContent{
                            background-color: #5f6065;
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #ff1616;
                            img{
                                width: 30px;
                                height: 30px;
                            }
                        }
                        span{
                            color: white;
            
                            font-size: 14px;
                        }
                        h4{
                            color: #ff1616;
                            font-size: 16px;
            
                        }
                    }
                }
            }
        }
        .music{
            width: 100%;
            height: 300px;
            background-color: #181818;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            gap: 50px;
            overflow-y: scroll;
            padding: 10px;
            .title{
                color: white;
                font-size: 25px;

                font-weight: bold;
            }
            .content{
                display: flex;
                flex-direction: column;
                .top{
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    color: #5f6065;
                    font-size: 16px;
                    @include tablet{
                        font-size: 10px;
                    }
    
                    .titre{
                        width: 50%;
                        @include tablet{
                            width: 31%;
                        }
                    }
                    .date{
                        width: 18%;
                        @include tablet{
                            width: 23%;
                        }
                    }
                    .status{
                        width: 16%;
                        @include tablet{
                            width: 23%;
                        }
                    }
                    .promute{
                        width: 16%;
                        @include tablet{
                            width: 23%;
                        }
                    }
                }
                hr{
                    width: 100%;
                    color: #5f6065;
                    height: 2px;
                    font-size: 5px;
                    background-color: white;
                }
                .contentBox{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }
            }
        }
        .music::-webkit-scrollbar {
            width: 4px;
        }
        .music::-webkit-scrollbar-track {
            background-color: transparent;
          }
          
          .music::-webkit-scrollbar-thumb {
            background-color: #ff1616;
            border-radius: 4px;
          }
          
          .music::-webkit-scrollbar-thumb:hover {
            background-color: #555;
          }
        .chartContainer{
            display: flex;
            justify-content: space-between;
            @include tablet{
                flex-direction: column;
                gap: 50px;
            }
            .revenu{
                width: 48%;
                background-color: #181818;
                display: flex;
                flex-direction: column;
                padding: 20px;
                gap: 20px;
                border-radius: 20px;
                @include tablet{
                    width: 100%;
                }
                .top{
                    background-color: #5f6065;
                    width: 40px;
                    height: 30px;
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #ff1616;
                    position: relative;
                    left: 90%;
                }
                .bottom{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    .stats{
                        display: flex;
                        color: #ff1616;
                        justify-content: space-between;
                        width: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        .content{
                            span{
                                font-size:  8px;
                            }
                            h4{
                                font-size: 16px;
                            }
                        }
                    }
                    .objectif{
                        position: relative;
                        width: 80%;
                        margin-left: auto;
                        margin-right: auto;
                        span{
                            color:#5f6065;
                            font-size: 10px;
                        }
                        .slider{
                            height: 10px;
                            // border-top-left-radius: ;
                        }
                    }
                }
            }
            .topGender{
                width: 48%;
                background-color: #181818;
                display: flex;
                flex-direction: column;
                padding: 10px;
                border-radius: 20px;
                gap: 50px;
                @include tablet{
                    width: 100%;
                }
                .top{
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    .TopGenderIcon{
                        background-color: #5f6065;
                        width: 30px;
                        height: 30px;
                        border-radius: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #ff1616;
                    }
                    span{
                        color: white;
                        font-size: 25px;
        
                    }
                }
                .content{
                    display: flex;
                    flex-direction: column;
                    color: white;
                    width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                    gap: 20px;
                    .box{
                        display: flex;
                        flex-direction: column;
                        .boxTop{
            
                            font-size: 16px;
                            font-weight: bold;
                        }
                        .boxBottom{
                            display: flex;
                            span{
                                width: 100px;
                                color: #ff1616;
                
                                font-size: 16px;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
            .weekly{
                width: 48%;
                background-color: #181818;
                display: flex;
                flex-direction: column;
                padding: 20px;
                gap: 20px;
                border-radius: 20px;
                @include tablet{
                    width: 100%;
                    height: 400px;
                }
                .top{
                    background-color: #5f6065;
                    width: 40px;
                    height: 30px;
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #ff1616;
                    position: relative;
                    left: 90%;
                }
                .chart{
                    height: 100%;
                    position: relative;
                }
            }
        }
        .traffic{
            display: flex;
            justify-content: space-between;
            @include tablet{
                flex-direction: column;
                gap: 50px;
            }
            
            .dailyTraffic{
                width: 48%;
                background-color: #181818;
                padding: 10px;
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                gap: 30px;
                @include tablet{
                    width: 100%;
                    height: 500px;
                }
                .top{
                    color: white;
                    display: flex;
                    justify-content: space-between;
                    height: 10%;
                    span{
                        font-size: 20px;
        
                        font-weight: bold;
                    }
                    .topGenderRight{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: greenyellow;
                    }
                }
                .topGenderBottom{
                    display: flex;
                    color: white;
                    align-items: center;
                    gap: 20px;
                    height: 10%;
                    h4{
                        font-size: 25px;
        
                        font-weight: bold;
                    }
                    span{
                        font-size: 12px;
        
                        font-weight: bold;
                    }
                }
                .topGenderBottomChart{
                    display: flex;
                    flex-direction: column;
                    height: 80%;
                    
                }
            }
        }
        .royalties{
            width: 100%;
            height: 300px;
            background-color: #181818;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            gap: 50px;
            overflow-y: scroll;
            padding: 10px;
            .title{
                color: white;
                font-size: 25px;

                font-weight: bold;
            }
            .content{
                display: flex;
                flex-direction: column;
                .top{
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    color: #5f6065;
                    font-size: 16px;
    
                    @include tablet{
                        font-size: 8px;
                    }
                    .royaltiesMusic{
                        width: 40%;
                        @include tablet{
                            width: 30%;
                        }
                    }
                    .spotify{
                        width: 10%;
                        @include tablet{
                            width: 14%;
                        }
                    }
                    .apple{
                        width: 10%;
                        @include tablet{
                            width: 14%;
                        }
                    }
                    .google{
                        width: 10%;
                        @include tablet{
                            width: 14%;
                        }
                    }
                    .autre{
                        width: 10%;
                        @include tablet{
                            width: 14%;
                        }
                    }
                    .youtube{
                        width: 10%;
                        @include tablet{
                            display: none;
                        }
                    }
                    .total{
                        width: 10%;
                        @include tablet{
                            width: 14%;
                        }
                    }
                }
                hr{
                    width: 100%;
                    color: #5f6065;
                    height: 2px;
                    font-size: 5px;
                    background-color: white;
                }
                .contentBox{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    .box{
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 16px;
        
                        color: green;
                        @include tablet{
                            font-size: 12px;
                        }
                        .royaltiesMusic{
                            width: 40%;
                            color: #5f6065;
                            @include tablet{
                                width: 30%;
                            }
                        }
                        .spotify{
                            width: 10%;
                            @include tablet{
                                width: 14%;
                            }
                        }
                        .apple{
                            width: 10%;
                            @include tablet{
                                width: 14%;
                            }
                        }
                        .google{
                            width: 10%;
                            @include tablet{
                                width: 14%;
                            }
                        }
                        .autre{
                            width: 10%;
                            @include tablet{
                                width: 14%;
                            }
                        }
                        .youtube{
                            width: 10%;
                            @include tablet{
                                display: none;
                            }
                        }
                        .total{
                            width: 10%;
                            @include tablet{
                                width: 14%;
                            }
                        }
    
                    }
                }
            }
        }
        .royalties::-webkit-scrollbar {
            width: 4px;
        }
        .royalties::-webkit-scrollbar-track {
            background-color: transparent;
        }
          
        .royalties::-webkit-scrollbar-thumb {
            background-color: #ff1616;
            border-radius: 4px;
        }
          
        .royalties::-webkit-scrollbar-thumb:hover {
        background-color: #555;
        }
        .withdrawal{
            width: 100%;
            height: 300px;
            background-color: #181818;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            gap: 50px;
            overflow-y: scroll;
            padding: 10px;
            .title{
                color: white;
                font-size: 25px;

                font-weight: bold;
            }
            .content{
                display: flex;
                flex-direction: column;
                .top{
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    color: #5f6065;
                    font-size: 16px;
                    gap: 20px;
                    
                    @include tablet{
                        font-size: 10px;
                    }
                    .montant{
                        width: 25%;
                    }
                    .status{
                        width: 25%;
                    }
                    .id{
                        width: 25%;
                    }
                    .methodes{
                        width: 25%;
                    }
                }
                hr{
                    width: 100%;
                    color: #5f6065;
                    height: 2px;
                    font-size: 5px;
                    background-color: white;
                }
                .contentBox{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    width: 100%;
                    .box{
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        align-items: center;
                        color: #5f6065;
                        font-size: 16px;
                        gap: 20px;
                        @include tablet{
                            font-size: 10px;
                        }
                        .id{
                            width: 25%;
                        }
                        .montant{
                            width: 25%;
                            color:#ff1616;
                        }
                        .methodes{
                            width: 25%;
                        }
                        .status{
                            width: 25%;
                            font-size: 16px;
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            @include tablet{
                                font-size: 10px;
                            }
                            .statusDoIcon{
                                width: 15px;
                                height: 15px;
                                background-color: green;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .icon{
                                    font-size: 14px;
                                    color: white;
                                }
                                @include tablet{
                                    width: 10px;
                                    height: 10px;
                                    .icon{
                                        font-size: 10px;
                                    }
                                }
                            }
                            .statusNoIcon{
                                width: 15px;
                                height: 15px;
                                background-color: white;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .icon{
                                    font-size: 14px;
                                    color:#ff1616;
                                }
                            }
                            .statusInIcon{
                                width: 15px;
                                height: 15px;
                                background-color: yellow;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .icon{
                                    font-size: 14px;
                                    color: black;
                                }
                            }
                        }
    
                    }
                }
            }
        }
        .withdrawal::-webkit-scrollbar {
            width: 4px;
        }
        .withdrawal::-webkit-scrollbar-track {
            background-color: transparent;
        }
          
        .withdrawal::-webkit-scrollbar-thumb {
            background-color: #ff1616;
            border-radius: 4px;
        }
          
        .withdrawal::-webkit-scrollbar-thumb:hover {
        background-color: #555;
        }
        .copyright{
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            color: white;
            font-weight: bold;
            text-align: center;
            text-align: center;
        }
    }
}