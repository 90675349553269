@import '../../style.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');

.retrait{
    margin: 0;
    background-color: black;
    width: 100%;
    // height: 100vh;
    min-height: 100vh;
    color: white;
    font-family: 'Lato', sans-serif;
    .rightSide{
        position: relative;
        display: flex;
        flex-direction: column;
        left: 22%;
        width: 76%;
        padding-top: 50px;
        gap: 50px;
        height: 90%;
        @include tablet{
            left: 0%;
            margin-left: auto;
            margin-right: auto;
            width: 90%;
        }
        .body{
            height: 90%;
            display: flex;
            gap: 100px;
            padding-top: 100px;
            @include tablet{
                flex-direction: column;
            }
            .payementMode{
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                height: 500px;
                justify-content: space-between;
                @include tablet{
                    width: 100%;
                }
                .box{
                    display: flex;
                    justify-content: space-between;
                    height: 80px;
                    background-color: white;
                    align-items: center;
                    border-radius: 20px;
                    padding: 0 10px 0 10px;
                    .boxLeft{
                        color: red;
                    }
                    .boxRight{
                        display: flex;
                        gap: 10px;
                        img{
                            width:80px;
                            height: auto;
                        }
                    }
                }
                .box.active{
                    background-color: red;
                    color: white;
                    .boxLeft{
                        color: white;
                    }
                }
            }
            .payementOptions{
                display: flex;
                flex-direction: column;
                width: 50%;
                color: white;
                @include tablet{
                    width: 100%;
                    h4{
                        text-align: center;
                    }
                }
                form{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 20px;
                    h4{
                        color: red;
                        text-decoration: underline;
                    }
                    .montant{
                        display: flex;
                        gap: 20px;
                        input{
                            width: 80%;
                            color: green;
                        }
                        select{
                            width: 20%;
                            background-color: transparent;
                            border-radius: 20px;
                            border: 2px solid red;
                            color: white;
                            outline: none;
                        }
                    }
                    .montant.depasse{
                        input{
                            color: red;
                        }
                    }
                    label{
                        font-size: 12px;
                    }
                    input{
                        height: 50px;
                        border-radius: 20px;
                        background-color: transparent;
                        border:2px solid red;
                        padding:0 10px 0 10px;
                        color: white;
                        outline: none;
                    }
                    input[type="submit"]{
                        background-color: red;
                        color: white;
                    }
                }
            }
        }
        .copyright{
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            color: white;
            font-family: 'Montserrat', sans-serif;
            font-weight: bold;
            text-align: center;
        }
    }
}