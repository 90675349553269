@import "../../style.scss";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

@media only screen and (min-width: 1120px){
@include themify($themes) {
  .signup{
    
    .div-main {
      background-size: cover;
      background-position: center;
      background-image: url('/assets/portrait-of-woman-singing-on-microphone.jpg');
      display: flex;
    }
  
    .div-main2 {
      font-Family: 'Lato, sans-serif';
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 1100px;
      width: 100%;
      // background-color: rgba(200, 200, 200, 0.5);
  
      .top-div {
        display: flex;
        justify-content: space-between;
  
        background-color: rgba(34, 32, 32, 0.8);
        width: 70%;
        max-width: 1200px;
        height: 100px;
        margin-top: 100px;
        .text {
          padding: 10px;
          //border: 1px solid #ccc;
          color: white;
          font-size: 20px;
        }
  
        .left {
          //  justify-items: left;
          display: flex;
          flex-direction: column;
          padding-left: 45px;
        }
  
        .right {
          display: flex;
          flex-direction: column;
          padding-right: 45px;
        }
      }
  
      .centered-div {
        display: flex;
        width: 70%;
        max-width: 1200px;
        align-items: center;
        margin-top: 20px;
        justify-content: center;
  
        .form-container {
          background-color: rgba(34, 32, 32, 0.8);
          padding-top: 20px;
          padding-bottom: 20px;
          padding-left: 30px;
          width: 50%;
          padding-right: 35px;
  
          input {
            background-color: rgba(220, 220, 220, 0.7);
            // padding-right: 10px;
            margin-bottom: 20px;
            margin-top: 20px;
            border: none;
            border-radius: 4px;
            width: 95%;
            height: 45px;
            font-size: 20px;
            color: #ffffff;
            outline: none;
            &::placeholder {
              color: #ffffff;
              font-size: 20px;
            }
          }
  
          .select-form {
            background-color: rgba(220, 220, 220, 0.7);
            margin-bottom: 20px;
            margin-top: 20px;
            border: none;
            border-radius: 4px;
            width: 95%;
            height: 45px;
            font-size: 20px;
            color: rgb(255, 255, 255);
  
            option {
              background-color: rgba(86, 84, 84, 0.7);
              margin-bottom: 20px;
              margin-top: 20px;
              border: none;
              border-radius: 4px;
              width: 100%;
              height: 45px;
              font-size: 20px;
              color: white;
            }
          }
  
          #button-sigin {
            background-color: rgb(255, 0, 0);
            border: none;
            font-size: 20px;
            font-weight: bold;
            color: #ffffff;
            height: 45px;
            border-radius: 12px;
            width: 250px;
          }
  
          .conditions-container {
            display: flex;
            align-items: center;
            color: white;
          }
  
          .conditions-checkbox {
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
  
          .conditions-label {
            display: flex;
            align-items: center;
            cursor: pointer;
            button{
              background-color: transparent;
              border: none;
              color: white;
              text-decoration: underline;
            }
          }
  
          .modal {
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 999;
          }
  
          .modal-content {
            position: relative;
            background-color: #ffffff;
            padding: 20px;
            max-width: 600px;
            color: #020202;
          }
  
          .close {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            font-size: 29px;
            color: #fc1010;
          }
  
          .conditions-link {
            color: rgb(231, 228, 228);
          }
  
          button.disabled {
            opacity: 0.4;
            cursor: not-allowed;
          }
  
          .error {
            color: #f60101;
          }
  
          .logo-icon {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
          }
  
          .logo-icon {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }
  
          .button-text {
            vertical-align: middle;
          }
  
          .provider-button {
            display: flex;
            align-items: center;
            background-color: rgba(220, 220, 220, 0.7);
            // padding-right: 10px;
            margin-bottom: 45px;
            margin-top: 4s5px;
            border: none;
            border-radius: 4px;
            width: 100%;
            height: 45px;
            font-size: 20px;
            color: #fff;
  
            &::placeholder {
              color: #fff;
              font-size: 20px;
            }
          }
        }
      }
  
      .ou {
        color: #fff;
        margin-left: 10px;
        margin-right: 10px;
        font-weight: bold;
        font-size: 20px;
      }
  
      .red-pi-check {
        color: red;
      }
  
    }
  }
}
}

@media only screen and (min-width: 981px) and (max-width: 1119px) {
  @include themify($themes) {
    .signup{

      .div-main {
        background-size: cover;
        background-position: center;
        background-image: url('/assets/portrait-of-woman-singing-on-microphone.jpg');
        display: flex;
      }
      
      .div-main2 {
        font-family: 'Lato', sans-serif;
        display: flex;
        height: 1100px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        // background-color: rgba(200, 200, 200, 0.5);
  
        .top-div {
          display: flex;
          justify-content: space-between;
          background-color: rgba(34, 32, 32, 0.8);
          width: 100%;
          max-width: 800px;
          height: 100px;
  
          .text {
            padding: 10px;
            color: white;
            font-size: 20px;
          }
  
          .left {
            display: flex;
            flex-direction: column;
            padding-left: 45px;
          }
  
          .right {
            display: flex;
            flex-direction: column;
            padding-right: 45px;
          }
        }
  
        .centered-div {
          display: flex;
          width: 100%;
          align-items: center;
          margin-top: 20px;
          max-width: 800px;
          justify-content: center;
  
          .form-container {
            background-color: rgba(34, 32, 32, 0.8);
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 30px;
            width: 70%;
            max-width: 500px;
            padding-right: 35px;
  
            input {
              background-color: rgba(220, 220, 220, 0.7);
              margin-bottom: 20px;
              margin-top: 20px;
              border: none;
              border-radius: 4px;
              width: 95%;
              height: 45px;
              font-size: 20px;
              color: #ffffff;
              outline: none;
              &::placeholder {
                color: #ffffff;
                font-size: 20px;
              }
            }
  
            .select-form {
              background-color: rgba(220, 220, 220, 0.7);
              margin-bottom: 20px;
              margin-top: 20px;
              border: none;
              border-radius: 4px;
              width: 95%;
              height: 45px;
              font-size: 20px;
              color: rgb(255, 255, 255);
  
              option {
                background-color: rgba(86, 84, 84, 0.7);
                margin-bottom: 20px;
                margin-top: 20px;
                border: none;
                border-radius: 4px;
                width: 100%;
                height: 45px;
                font-size: 20px;
                color: white;
              }
            }
  
            #button-sigin {
              background-color: rgb(255, 0, 0);
              border: none;
              font-size: 20px;
              font-weight: bold;
              color: #ffffff;
              height: 45px;
              border-radius: 12px;
            }
  
            .conditions-container {
              display: flex;
              align-items: center;
              color: white;
            }
  
            .conditions-checkbox {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
  
            .conditions-label {
              display: flex;
              align-items: center;
              cursor: pointer;
              
              button{
                background-color: transparent;
                border: none;
                color: white;
                text-decoration: underline;
              }
            }
  
            .modal {
              display: flex;
              align-items: center;
              justify-content: center;
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.5);
              z-index: 999;
            }
  
            .modal-content {
              position: relative;
              background-color: #ffffff;
              padding: 20px;
              max-width: 600px;
              color: #020202;
            }
  
            .close {
              position: absolute;
              top: 10px;
              right: 10px;
              cursor: pointer;
              font-size: 29px;
              color: #fc1010;
            }
  
            .conditions-link {
              color: rgb(231, 228, 228);
            }
  
            button.disabled {
              opacity: 0.4;
              cursor: not-allowed;
            }
  
            .error {
              color: #f60101;
            }
  
            .logo-icon {
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
            }
  
            .logo-icon {
              width: 24px;
              height: 24px;
              margin-right: 10px;
            }
  
            .button-text {
              vertical-align: middle;
            }
  
            .provider-button {
              display: flex;
              align-items: center;
              background-color: rgba(220, 220, 220, 0.7);
              margin-bottom: 45px;
              margin-top: 45px;
              border: none;
              border-radius: 4px;
              width: 100%;
              height: 45px;
              font-size: 20px;
              color: #fff;
  
              &::placeholder {
                color: #fff;
                font-size: 20px;
              }
            }
          }
        }
  
        .ou {
          color: #fff;
          margin-left: 10px;
          margin-right: 10px;
          font-weight: bold;
          font-size: 20px;
        }
  
        .red-pi-check {
          color: red;
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  @include themify($themes) {
    .signup{
      .div-main {
        background-size: cover;
        background-position: center;
        background-image: url('/assets/portrait-of-woman-singing-on-microphone.jpg');
        display: flex;
        padding-top: 50px;
        padding-bottom: 50px;
      }
  
      .div-main2 {
        font-family: 'Lato', sans-serif;
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: center;
        height: 900px;
        width: 100%;
        // background-color: rgba(200, 200, 200, 0.5);
  
        .top-div {
          display: none;
          /* display: flex;
          flex-direction: column;
  
          .text {
            padding: 10px;
            color: white;
            font-size: 20px;
          }
  
          .left,
          .right {
            display: flex;
            flex-direction: column;
            padding: 0 20px;
          }*/
        }
  
        .centered-div {
          display: flex;
          flex-direction: column;
          align-items: center;
  
          .form-container {
            background-color: rgba(34, 32, 32, 0.8);
            padding: 20px;
            width: 90%;
            max-width: 500px;
  
            input {
              background-color: rgba(220, 220, 220, 0.8);
              margin-bottom: 20px;
              border: none;
              border-radius: 4px;
              width: 100%;
              max-width: 500px;
              height: 50px;
              font-size: 16px;
              color: #ffffff;
              outline: none;
  
              &::placeholder {
                color: #ffffff;
                font-size: 16px;
              } 
            }
  
            .select-form {
              background-color: rgba(220, 220, 220, 0.7);
              margin-bottom: 20px;
              border: none;
              border-radius: 4px;
              width: 100%;
              max-width: 500px;
              height: 45px;
              font-size: 16px;
              color: rgb(255, 255, 255);
              outline: none;
  
              option {
                background-color: rgba(86, 84, 84, 0.7);
                margin-bottom: 20px;
                border: none;
                border-radius: 4px;
                width: 100%;
                height: 45px;
                font-size: 16px;
                color: white;
              }
            }
  
            #button-sigin {
              background-color: rgb(255, 0, 0);
              border: none;
              font-size: 20px;
              font-weight: bold;
              color: #ffffff;
              width: 100%;
              max-width: 500px;
              border-radius: 4px;
              height: 45px;
            }
  
            .conditions-container {
              display: flex;
              color: white;
              font-size: 12px;
              position: relative;
              top: -5px;
              .conditions-checkbox {
                width: 16px;
                height: 16px;
                margin-right: 8px;
              }
              .conditions-label {
                position: relative;
                top: -3px;
                button{
                  background-color: transparent;
                  border: none;
                  color: white;
                  text-decoration: underline;
                }
              }
            }
  
  
  
            .modal {
              display: flex;
              align-items: center;
              justify-content: center;
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.5);
              z-index: 999;
            }
  
            .modal-content {
              position: relative;
              background-color: #ffffff;
              padding: 20px;
              max-width: 600px;
              color: #020202;
            }
  
            .close {
              position: absolute;
              top: 10px;
              right: 10px;
              cursor: pointer;
              font-size: 29px;
              color: #fc1010;
            }
  
            .conditions-link {
              color: rgb(231, 228, 228);
            }
  
            button.disabled {
              opacity: 0.4;
              cursor: not-allowed;
            }
  
            .error {
              color: #f60101;
            }
  
            .logo-icon {
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
            }
  
            .logo-icon {
              width: 24px;
              height: 24px;
              margin-right: 10px;
            }
  
            .button-text {
              vertical-align: middle;
            }
  
            .provider-button {
              display: flex;
              align-items: center;
              background-color: rgba(220, 220, 220, 0.7);
              margin-bottom: 45px;
              margin-top: 45px;
              border: none;
              border-radius: 4px;
              width: 100%;
              height: 45px;
              font-size: 20px;
              color: #fff;
  
              &::placeholder {
                color: #fff;
                font-size: 20px;
              }
            }
          }
        }
  
        .ou {
          color: #fff;
          margin-left: 10px;
          margin-right: 10px;
          font-weight: bold;
          font-size: 20px;
        }
  
        .red-pi-check {
          color: red;
        }
      }
    }
  }
}
