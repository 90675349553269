@import "../../style.scss";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap");

body {
    background-color: black;
}


.main_block{
    .titre{
        text-align: center;
        color: red;
        padding-top: 10%;
        @media (max-width: 950px) {
            padding-top: 30%;
        }
    }

    .app {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        //padding-top: 7%;
        margin-bottom: 5%;
        margin-left: 150px;
        margin-right: 150px;

        @media (max-width: 950px) {
            margin-left: 40px;
            margin-right: 5px;
            //padding-top: 10%;
        }
    }

    .carte {
        position: relative;
        border-radius: 10px;
        width: 450px;
        height: 400px;
        background-color: #100d0d;
        -webkit-box-shadow: 1px 1px 12px #000;
        box-shadow: 1px 1px 12px #000;

        -webkit-perspective: 2000px;
        perspective: 2000px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: #000;
        margin-top: 60px;
        margin-bottom: 50px;

        @media (max-width: 1825px) and (min-width: 1700px) {
            width: 400px;
            height: 300px;
        }

        @media (max-width: 1700px) and (min-width: 1500px) {
            width: 350px;
            height: 300px;
        }

        @media (max-width: 1500px) and (min-width: 1370px) {
            width: 300px;
            height: 250px;
        }

        @media (max-width: 1370px) and (min-width: 1250px) {
            width: 450px;
            height: 350px;
        }

        @media (max-width: 1250px) and (min-width: 1035px) {
            width: 350px;
            height: 350px;
        }

        @media (max-width: 1035px) and (min-width: 950px) {
            width: 300px;
            height: 250px;
        }

        @media (max-width: 950px) {
            width: 90%;
            margin-bottom: 10%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 15px;
            margin-bottom: 70px;
        }

        .image-title12 {
            position: absolute;
            bottom: -50px;
            color: white;
            font-size: 20px;
            font-weight: bold;
            margin-top: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            border-radius: 15px;

            .button12 {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 25px;
                font-size: 16px;
                background-color: red;
                color: white;
                border: none;
                cursor: pointer;
                &:hover {
                    background-color: darkred;
                }
            }
        }

        .content {
            text-align: center;
            align-items: center;
        }

        .content {
            margin-left: 50px;
            margin-right: 10px;
        }
    }

    .cover12 {
        top: 0;
        position: absolute;
        background-color: #100d0d;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.5s;
        transform-origin: 0;
        box-shadow: 1px 1px 12px #000;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .carte:hover .cover {
        transition: all 0.5s;
        transform: rotatey(-80deg);
    }

    p {
        font-size: 20px;
        font-weight: bolder;
    }

    .full-content {
        padding-right: 100px;
    }

}
