@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Open+Sans:wght@700&display=swap');
@import '../../style.scss';

.admin{
    margin: 0;
    background-color: black;
    width: 100%;
    height: auto;
    .rightSide{
        position: relative;
        display: flex;
        flex-direction: column;
        left: 22%;
        width: 76%;
        padding-top: 50px;
        gap: 50px;
        @include tablet{
            left: 0%;
            margin-left: auto;
            margin-right: auto;
            width: 90%;
        }
        .navbar{
            position: relative;
            background-color: #181818;
            display: flex;
            justify-content: end;
            width: 100%;
            height: 70px;
            border-radius: 20px;
            padding: 10px;
            .navbarIcon{
                display: none;
            }
            @include tablet{
                position: fixed;
                width: 90%;
                justify-content: space-between;
                .navbarIcon{
                    display: flex;
                    gap: 20px;
                }
            }
            .content{
                background-color: black;
                padding:10px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                gap: 30px;
                position: relative;
                input{
                    height: 30px;
                    background-color:#5f6065;
                    border: none;
                    border-radius: 20px;
                    padding: 10px;
                    outline: none;
                    color: white;
                    &::placeholder {
                        color: #c2c2c2;
                        position: relative;
                    }
                }
                img{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }
            }
        }
        .chiffre{
            display: flex;
            width: 100%;
            gap: 30px;
            flex-direction: column;
            @include tablet{
                margin-top: 100px;
            }
            .content{
                display: flex;
                align-items: center;
                width: 100%;
                gap: 30px;
                @include tablet{
                    flex-direction: column;
                }
                .box{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    width: 100%;
                    height: 80px;
                    background-color: #181818;
                    border-radius: 20px;
                    padding: 20px;
                    @include tablet{
                        justify-content: center;
                    }
                    .boxContent{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        @include tablet{

                            width: 50%;
                        }
                        .iconContent{
                            background-color: #5f6065;
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #ff1616;
                            img{
                                width: 30px;
                                height: 30px;
                            }
                        }
                        span{
                            color: white;
                            font-family: 'Montserrat', sans-serif;
                            font-size: 14px;
                        }
                        h4{
                            color: #ff1616;
                            font-size: 16px;
                            font-family: 'Montserrat', sans-serif;
                        }
                    }
                }
            }
        }
        .music{
            width: 100%;
            height: 500px;
            background-color: #181818;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            gap: 50px;
            overflow-y: scroll;
            padding: 10px;
            .title{
                color: white;
                font-size: 25px;
                font-family: 'Montserrat', sans-serif;
                font-weight: bold;
            }
            .content{
                display: flex;
                flex-direction: column;
                .top{
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    color: #5f6065;
                    font-size: 16px;
                    font-family: 'Montserrat', sans-serif;
                    @include tablet{
                        font-size: 10px;
                    }
                    .titre{
                        width: 50%;
                    }
                    .date{
                        width: 16%;
                    }
                    .status{
                        width: 18%;
                    }
                    .promute{
                        width: 16%;
                    }
                }
                hr{
                    width: 100%;
                    color: #5f6065;
                    height: 2px;
                    font-size: 5px;
                    background-color: white;
                }
                .contentBox{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }
            }
        }
        .music::-webkit-scrollbar {
            width: 4px;
        }
        .music::-webkit-scrollbar-track {
            background-color: transparent;
        }
          
        .music::-webkit-scrollbar-thumb {
            background-color: #ff1616;
            border-radius: 4px;
        }
          
        .music::-webkit-scrollbar-thumb:hover {
        background-color: #555;
        }
        .chartContainer{
            display: flex;
            justify-content: space-between;
            @include tablet{
                flex-direction: column;
                gap: 50px;
            }
            .revenu{
                width: 48%;
                background-color: #181818;
                display: flex;
                flex-direction: column;
                padding: 20px;
                gap: 20px;
                border-radius: 20px;
                height: 500px;
                @include tablet{
                    width: 100%;
                }
                .top{
                    background-color: #5f6065;
                    width: 40px;
                    height: 30px;
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #ff1616;
                    position: relative;
                    left: 90%;
                }
                .bottom{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    .stats{
                        display: flex;
                        color: #ff1616;
                        justify-content: space-between;
                        width: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        .content{
                            span{
                                font-size:  8px;
                            }
                            h4{
                                font-size: 16px;
                            }
                        }
                    }
                    .objectif{
                        position: relative;
                        width: 80%;
                        margin-left: auto;
                        margin-right: auto;
                        span{
                            color:#5f6065;
                            font-size: 10px;
                        }
                        .slider{
                            height: 10px;
                            // border-top-left-radius: ;
                        }
                    }
                }
            }
            .weekly{
                width: 48%;
                background-color: #181818;
                display: flex;
                flex-direction: column;
                padding: 20px;
                gap: 20px;
                border-radius: 20px;
                height: 500px;
                @include tablet{
                    width: 100%;
                    height: 400px;
                }
                .top{
                    background-color: #5f6065;
                    width: 40px;
                    height: 30px;
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #ff1616;
                    position: relative;
                    left: 90%;
                }
                .chart{
                    height: 100%;
                    position: relative;
                }
            }
        }
        .traffic{
            display: flex;
            justify-content: space-between;
            @include tablet{
                flex-direction: column;
                gap: 50px;
            }
            .topGender{
                width: 48%;
                background-color: #181818;
                display: flex;
                flex-direction: column;
                padding: 10px;
                border-radius: 20px;
                gap: 50px;
                height: 500px;
                @include tablet{
                    width: 100%;
                }
                .top{
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    .TopGenderIcon{
                        background-color: #5f6065;
                        width: 30px;
                        height: 30px;
                        border-radius: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #ff1616;
                    }
                    span{
                        color: white;
                        font-size: 25px;
                        font-family: 'Montserrat', sans-serif;
                    }
                }
                .content{
                    display: flex;
                    flex-direction: column;
                    color: white;
                    width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                    gap: 20px;
                    .box{
                        display: flex;
                        flex-direction: column;
                        .boxTop{
                            font-family: 'Montserrat', sans-serif;
                            font-size: 16px;
                            font-weight: bold;
                        }
                        .boxBottom{
                            display: flex;
                            span{
                                width: 100px;
                                color: #ff1616;
                                font-family: 'Montserrat', sans-serif;
                                font-size: 16px;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
            .dailyTraffic{
                width: 48%;
                background-color: #181818;
                padding: 10px;
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                gap: 30px;
                height: 500px;
                @include tablet{
                    width: 100%;
                    height: 500px;
                }
                .top{
                    color: white;
                    display: flex;
                    justify-content: space-between;
                    height: 10%;
                    span{
                        font-size: 20px;
                        font-family: 'Montserrat', sans-serif;
                        font-weight: bold;
                    }
                    .topGenderRight{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: greenyellow;
                    }
                }
                .topGenderBottom{
                    display: flex;
                    color: white;
                    align-items: center;
                    gap: 20px;
                    height: 10%;
                    h4{
                        font-size: 25px;
                        font-family: 'Montserrat', sans-serif;
                        font-weight: bold;
                    }
                    span{
                        font-size: 12px;
                        font-family: 'Montserrat', sans-serif;
                        font-weight: bold;
                    }
                }
                .topGenderBottomChart{
                    display: flex;
                    flex-direction: column;
                    height: 80%;
                    
                }
            }
        }
        .royalties{
            width: 100%;
            height: 300px;
            background-color: #181818;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            gap: 50px;
            overflow-y: scroll;
            padding: 10px;
            .title{
                color: white;
                font-size: 25px;
                font-family: 'Montserrat', sans-serif;
                font-weight: bold;
            }
            .content{
                display: flex;
                flex-direction: column;
                .top{
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    color: #5f6065;
                    font-size: 16px;
                    font-family: 'Montserrat', sans-serif;
                    @include tablet{
                        font-size: 12px;
                    }
                    .royaltiesMusic{
                        width: 40%;
                    }
                    .spotify{
                        width: 10%;
                    }
                    .apple{
                        width: 10%;
                    }
                    .google{
                        width: 10%;
                    }
                    .autre{
                        width: 10%;
                    }
                    .youtube{
                        width: 10%;
                    }
                    .total{
                        width: 10%;
                    }
                }
                hr{
                    width: 100%;
                    color: #5f6065;
                    height: 2px;
                    font-size: 5px;
                    background-color: white;
                }
                .contentBox{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    .box{
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 16px;
                        font-family: 'Montserrat', sans-serif;
                        color: green;
                        @include tablet{
                            font-size: 12px;
                        }
                        .royaltiesMusic{
                            width: 40%;
                            color: #5f6065;
                        }
                        .spotify{
                            width: 10%;
                        }
                        .apple{
                            width: 10%;
                        }
                        .google{
                            width: 10%;
                        }
                        .autre{
                            width: 10%;
                        }
                        .youtube{
                            width: 10%;
                        }
                        .total{
                            width: 10%;
                        }
                    }
                }
            }
        }
        .royalties::-webkit-scrollbar {
            width: 4px;
        }
        .royalties::-webkit-scrollbar-track {
            background-color: transparent;
        }
          
        .royalties::-webkit-scrollbar-thumb {
            background-color: #ff1616;
            border-radius: 4px;
        }
          
        .royalties::-webkit-scrollbar-thumb:hover {
        background-color: #555;
        }
        .withdrawal{
            width: 100%;
            height: 300px;
            background-color: #181818;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            gap: 50px;
            overflow-y: scroll;
            padding: 10px;
            .title{
                color: white;
                font-size: 25px;
                font-family: 'Montserrat', sans-serif;
                font-weight: bold;
            }
            .content{
                display: flex;
                flex-direction: column;
                .top{
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    color: #5f6065;
                    font-size: 16px;
                    font-family: 'Montserrat', sans-serif;
                    @include tablet{
                        font-size: 12px;
                    }
                    .titre{
                        width: 20%;
                    }
                    .montant{
                        width: 20%;
                    }
                    .status{
                        width: 15%;
                    }
                    .id{
                        width: 25%;
                    }
                    .methodes{
                        width: 20%;
                    }
                }
                hr{
                    width: 100%;
                    color: #5f6065;
                    height: 2px;
                    font-size: 5px;
                    background-color: white;
                }
                .contentBox{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    .box{
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        align-items: center;
                        color: #5f6065;
                        font-size: 16px;
                        font-family: 'Montserrat', sans-serif;
                        .more{
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            left: -30px;
                            text-align: center;
                            color: white;
                            transition: 5s;
                            animation-name: widthAnimation;
                            animation-duration: .2s;
                            animation-timing-function: ease;
                            animation-fill-mode: forwards;
                            height: 0px;
                            overflow: hidden;
                            width: 100px;
                            button{
                                background-color: #ff1616;
                                border: none;
                                border-radius: 10px;
                                width: 100px;
                                color: white;
                                font-family: 'Montserrat', sans-serif;
                            }
                            button.enCours{
                                background-color: yellow;
                                color: #c2c2c2;
                            }
                            button.publie{
                                background-color: green;
                            }
                        }
                        @keyframes widthAnimation {
                            0% {
                              height: 0;
                            }
                            50% {
                              height: 20;
                            }
                            100% {
                              height: 65px;
                            }
                          }
                        @include tablet{
                            font-size: 12px;
                        }
                        .titre{
                            width: 20%;
                        }
                        .id{
                            width: 25%;
                        }
                        .montant{
                            width: 20%;

                        }
                        .methodes{
                            width: 20%;
                        }
                        .status{
                            width: 15%;
                            font-size: 16px;
                            font-family: 'Montserrat', sans-serif;
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            @include tablet{
                                font-size: 12px;
                            }
                            .statusDoIcon{
                                width: 15px;
                                height: 15px;
                                background-color: green;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .icon{
                                    font-size: 14px;
                                    color: white;
                                }
                            }
                            .statusNoIcon{
                                width: 15px;
                                height: 15px;
                                background-color: white;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .icon{
                                    font-size: 14px;
                                    color:#ff1616;
                                }
                            }
                            .statusInIcon{
                                width: 15px;
                                height: 15px;
                                background-color: yellow;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .icon{
                                    font-size: 14px;
                                    color: black;
                                }
                            }
                        }
    
                    }
                }
            }
        }
        .withdrawal::-webkit-scrollbar {
            width: 4px;
        }
        .withdrawal::-webkit-scrollbar-track {
            background-color: transparent;
        }
          
        .withdrawal::-webkit-scrollbar-thumb {
            background-color: #ff1616;
            border-radius: 4px;
        }
          
        .withdrawal::-webkit-scrollbar-thumb:hover {
        background-color: #555;
        }
        .copyright{
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            color: white;
            font-family: 'Montserrat', sans-serif;
            font-weight: bold;
            text-align: center;
        }
    }
}