@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');
@import '../../style.scss';

.settings{
    position: relative;
    margin: 0;
    background-color: black;
    width: 100%;
    height: auto;
    font-family: 'Lato', sans-serif;
    .rightSide{
        position: relative;
        display: flex;
        flex-direction: column;
        // left: 22%;
        width: 50%;
        @include tablet{
            width: 90%;
            padding-top: 150px;
        }
        padding-top: 50px;
        margin-left: auto;
        margin-right: auto;
        gap: 50px;
        .profile{
            display: flex;
            flex-direction: column;
            position: relative;
            gap: 20px;
            .top{
                color: white;
                font-size: 25px;
                @include tablet{
                    font-size: 20px;
                    text-align: center;
                }
            }
            .bottom{
                display: flex;
                align-items: center;
                gap: 20px;
                .left{
                    img{
                        width: 70px;
                        height: 70px;
                        border-radius: 50%;
                    }
                }
                .right{
                    display: flex;
                    flex-direction: column;
                    color: white;
                    h4{
                        @include tablet{
                            font-size: 16px;
                        }
                    }
                    span{
                        color: #5f6065;
                        font-size: 14px;
                        @include tablet{
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .personal-infos{
            display: flex;
            flex-direction: column;
            color: white;
            width: 100%;
            gap: 20px;
            .title{
                display: flex;
                justify-content: space-between;
                @include tablet{
                    h4{
                        font-size: 16px;
                    }
                }
                button{
                    background-color: #ff1616;
                    border: none;
                    color: white;
                    border-radius: 10px;
                    width: 100px;
                }
            }
            .content{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .box{
                    @include tablet{
                        font-size: 14px;
                    }
                    display: flex;
                    justify-content: space-between;
                    // background-color: #5f6065;
                    height: 40px;
                    border: 1px solid #5f6065;
                    align-items: center;
                    padding: 0  20px 0 20px;
                }
            }
        }
        .adresse{
            display: flex;
            flex-direction: column;
            color: white;
            width: 100%;
            gap: 20px;
            .title{
                display: flex;
                justify-content: space-between;
            }
            .content{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .box{
                    @include tablet{
                        font-size: 14px;
                    }
                    display: flex;
                    justify-content: space-between;
                    // background-color: #5f6065;
                    height: 40px;
                    border: 1px solid #5f6065;
                    align-items: center;
                    padding: 0  20px 0 20px;
                }
            }
        }
        .social-network{
            display: flex;
            flex-direction: column;
            color: white;
            width: 100%;
            gap: 20px;
            .title{
                display: flex;
                justify-content: space-between;
            }
            .content{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .box{
                    @include tablet{
                        font-size: 14px;
                    }
                    display: flex;
                    // background-color: #5f6065;
                    height: 40px;
                    border: 1px solid #5f6065;
                    align-items: center;
                    padding: 0  20px 0 0px;
                    gap: 20px;
                    .personal-infos-label{
                        background-color: red;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        width: 150px;
                        justify-content: center;
                    }
                }
            }
        }
        .site-streaming{
            display: flex;
            flex-direction: column;
            color: white;
            width: 100%;
            gap: 20px;
            .title{
                display: flex;
                justify-content: space-between;
            }
            .content{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .box{
                    @include tablet{
                        font-size: 14px;
                    }
                    display: flex;
                    // background-color: #5f6065;
                    height: 40px;
                    border: 1px solid #5f6065;
                    align-items: center;
                    padding: 0  20px 0 0px;
                    gap: 20px;
                    .personal-infos-label{
                        background-color: red;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        width: 150px;
                        justify-content: center;
                    }
                }
            }
        }
    }
    .copyright{
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: white;
        font-weight: bold;
        text-align: center;
    }
    .rightSideModify{
        position: relative;
        display: flex;
        flex-direction: column;
        // left: 22%;
        width: 50%;
        @include tablet{
            width: 90%;
            padding-top: 150px;
        }
        padding-top: 50px;
        margin-left: auto;
        margin-right: auto;
        gap: 50px;
        color: white;
        .profile{
            display: flex;
            flex-direction: column;
            position: relative;
            gap: 20px;
            .top{
                color: white;
                font-size: 25px;
                @include tablet{
                    font-size: 20px;
                    text-align: center;
                }
            }
            .bottom{
                display: flex;
                align-items: center;
                gap: 20px;
                .left{
                    img{
                        width: 70px;
                        height: 70px;
                        border-radius: 50%;
                    }
                }
                .right{
                    display: flex;
                    flex-direction: column;
                    color: white;
                    h4{
                        @include tablet{
                            font-size: 16px;
                        }
                    }
                    span{
                        color: #5f6065;
                        font-size: 14px;
                    }
                }
            }
        }
        .personal-infos{
            display: flex;
            flex-direction: column;
            color: white;
            width: 100%;
            gap: 20px;
            .title{
                display: flex;
                justify-content: space-between;
                @include tablet{
                    h4{
                        font-size: 16px;
                    }
                }
                button{
                    background-color: #ff1616;
                    border: none;
                    color: white;
                    border-radius: 10px;
                    width: 100px;
                }
            }
            .content{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .box{
                    @include tablet{
                        font-size: 14px;
                    }
                    display: flex;
                    // background-color: #5f6065;
                    height: 40px;
                    border: 1px solid #5f6065;
                    align-items: center;
                    padding: 0  20px 0 0px;
                    gap: 20px;
                    label{
                        width: 30%;
                        background-color: #ff1616;
                        height: 40px;
                        display: flex;
                        align-items: center;
                    }
                    input{
                        width: 70%;
                        background-color: transparent;
                        border: none;
                        height: 40px;
                        color: white;
                        outline: none;
                    }
                }
            }
        }
        .adresse{
            display: flex;
            flex-direction: column;
            color: white;
            width: 100%;
            gap: 20px;
            .title{
                display: flex;
                justify-content: space-between;
                button{
                    background-color: #ff1616;
                    border: none;
                    color: white;
                    border-radius: 10px;
                    width: 100px;
                }
            }
            .content{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .box{
                    @include tablet{
                        font-size: 14px;
                    }
                    display: flex;
                    // background-color: #5f6065;
                    height: 40px;
                    border: 1px solid #5f6065;
                    align-items: center;
                    padding: 0  20px 0 0px;
                    gap: 20px;
                    label{
                        width: 30%;
                        background-color: #ff1616;
                        height: 40px;
                        display: flex;
                        align-items: center;
                    }
                    input{
                        width: 70%;
                        background-color: transparent;
                        border: none;
                        height: 40px;
                        color: white;
                        outline: none;
                    }
                    select{
                        background-color: transparent;
                        border: none;
                        outline: none;
                        color: white;
                        height: 40px;
                        width: 70%;
                    }
                    select option {
                        background-color:#5f6065;
                        font-size: 14px;
                        padding: 5px;
                    }
                }
            }
        }
        .social-network{
            display: flex;
            flex-direction: column;
            color: white;
            width: 100%;
            gap: 20px;
            .title{
                display: flex;
                justify-content: space-between;
                button{
                    background-color: #ff1616;
                    border: none;
                    color: white;
                    border-radius: 10px;
                    width: 100px;
                }
            }
            .content{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .box{
                    @include tablet{
                        font-size: 14px;
                    }
                    display: flex;
                    // background-color: #5f6065;
                    height: 40px;
                    border: 1px solid #5f6065;
                    align-items: center;
                    padding: 0  20px 0 0px;
                    gap: 20px;
                    label{
                        width: 30%;
                        background-color: #ff1616;
                        height: 40px;
                        display: flex;
                        align-items: center;
                    }
                    input{
                        width: 70%;
                        background-color: transparent;
                        border: none;
                        height: 40px;
                        color: white;
                        outline: none;
                    }
                }
            }
        }
        .streaming{
            display: flex;
            flex-direction: column;
            color: white;
            width: 100%;
            gap: 20px;
            .title{
                display: flex;
                justify-content: space-between;
                button{
                    background-color: #ff1616;
                    border: none;
                    color: white;
                    border-radius: 10px;
                    width: 100px;
                }
            }
            .content{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .box{
                    @include tablet{
                        font-size: 14px;
                    }
                    display: flex;
                    // background-color: #5f6065;
                    height: 40px;
                    border: 1px solid #5f6065;
                    align-items: center;
                    padding: 0  20px 0 0px;
                    gap: 20px;
                    label{
                        width: 30%;
                        background-color: #ff1616;
                        height: 40px;
                        display: flex;
                        align-items: center;
                    }
                    input{
                        width: 70%;
                        background-color: transparent;
                        border: none;
                        height: 40px;
                        color: white;
                        outline: none;
                    }
                }
            }
        }
        .password{
            display: flex;
            flex-direction: column;
            color: white;
            width: 100%;
            gap: 20px;
            .title{
                display: flex;
                justify-content: space-between;
                button{
                    background-color: #ff1616;
                    border: none;
                    color: white;
                    border-radius: 10px;
                    width: 100px;
                }
            }
            .content{
                display: flex;
                flex-direction: column;
                gap: 20px;
                .box{
                    @include tablet{
                        font-size: 14px;
                    }
                    display: flex;
                    // background-color: #5f6065;
                    height: 40px;
                    border: 1px solid #5f6065;
                    align-items: center;
                    padding: 0  20px 0 0px;
                    gap: 20px;
                    label{
                        width: 30%;
                        background-color: #ff1616;
                        height: 40px;
                        display: flex;
                        align-items: center;
                    }
                    input{
                        width: 70%;
                        background-color: transparent;
                        border: none;
                        height: 40px;
                        color: white;
                        outline: none;
                    }
                }
            }
            .action{
                width: 100%;
                display: flex;
                justify-content: space-between;
                button{
                    border: none;
                    border-radius: 20px;
                    width: 150px;
                    color: white;
                }
                button.btn1{
                    background-color:#ff1616;
                }
                button.btn2{
                    background-color: green;
                }
            }
        }
    }
}