@import '../../style.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');

@media only screen and (min-width: 980px){
    .contact{ 
        .tel{
            display: none;
        }
        .ordi{
            display: flex;
            flex-direction: column;
            position: relative;
            text-transform: uppercase;
            font-family: 'Lato', sans-serif;
            .ordi-img{
                position: absolute;
                width: 100%;
                background-image: url('../../assets/images/disk.jpg');
                height: 900px;
                background-size: cover;

            }
            .block{
                top: 0px;
                z-index: 2;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .top{
                    color: white;
                    font-size: 50px;
                    max-width: 700px;
                    text-align: center;
                    margin: 100px;
                }
                .bottom{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: end;
                    height: 555px;
                    margin-top: -100px;
                    gap: 100px;
                    max-width: 1000px;
                    .right{
                        display: flex;
                        flex-direction: column;
                        position: relative; 
                        h2{
                            font-size: 30px;
                            color: white;
                            text-align: center;
                        }
                        .content{
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                background-color: white;
                                border: 5px solid black;
                                border-radius: 50px;
                                padding: 20px;
                                gap: 10px;
                                a{
                                    text-decoration: none;
                                    color: #18191C;
                                    .box{
                                        display: flex;
                                        border: 5px solid black;
                                        width: 100%;
                                        height: 80px;
                                        border-radius: 20px;
                    
                                        .icon{
                                            width: 90px;
                                            height: 80px;
                                            border: 5px solid black;
                                            border-radius: 20px;
                                            position: relative;
                                            top: -5px;
                                            left: -5px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            img{
                                                width: 80%;
                                                height: 80%;
                                            }
                                        }
                                        .texte{
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: center;
                                            align-items: center;
                                            width: 200px;
                                            h2{
                                                font-size: 30px;
                                                // font-family: 'Gilda Display', serif;
                                                font-weight: bold;
                                                color: black;
                                            }
                                            span{
                                                font-size: 15px;
                                                font-weight: bold;
                                                position: relative;
                                                top: -10px;
                                            }
                                        }
                                        .send{
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            transform: rotate(-30deg);
                                            .SendSharpIcon{
                                                font-size: 35px;
                    
                                            }
                                        }
                                    }
                                }
                        }
                    }
                    .left{
                        width: 50%;
                        position: relative;
                        img{
                            transform: scaleX(0);
                            -webkit-transform: scaleX(-1); /* Pour une compatibilité avec certains navigateurs Webkit */
                            filter: FlipH;
                            width: 100%;
                            // top: -100px;
                            position: relative;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 980px){
    .ordi{
        display: none;
    }
    .tel{
        text-transform: uppercase;
        font-family: 'Lato', sans-serif;
        .first-block{
            background-image: url('../../assets/images/disk.jpg');
            background-size: cover;
            background-repeat: none;
            width: 100%;
            height: 900px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 0px 0px 50px 50px;
            background-color: red;
            background-position: center;
            span{
                position: absolute;
                font-size: 50px;
                color: white;
                font-weight: bold;
                font-family: 'Montserrat', sans-serif;
                text-align: center;
                max-width: 700px;
            }
        }
        .second-block{
            border-radius: 50px;
            display: flex;
            max-width: 1200px;
            margin: -50px 20px 20px 20px;
            height: auto;
            align-items: normal;
            left: auto;
            transform: none;
            background-color: #18191C;
            position: relative;
            flex-direction: column;
            justify-content: center;
            top: auto;
            right: auto;
            width: auto;
            .top{
                left: auto;
                display: flex;
                align-items: end;
                position: relative;
                img{
                    height: auto;
                    width: 100%;
                }
            }
            .bottom{
                display: flex;
                flex-direction: column;
                position: relative;
                left: auto;
                bottom: auto;
                margin-top: -50px;
                justify-content: space-between;
                h2{
                    font-size: 30px;
                    color: white;
                    text-align: center;
                }
                .content{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: white;
                    border: 5px solid black;
                    border-radius: 50px;
                    padding: 20px;
                    gap: 10px;
                    a{
                        text-decoration: none;
                        color: #18191C;
                        width: 98%;
                        .box{
                            display: flex;
                            border: 5px solid black;
                            width: 100%;
                            height: 80px;
                            border-radius: 20px;
                            .icon{
                                width: 90px;
                                height: 80px;
                                border: 5px solid black;
                                border-radius: 20px;
                                position: relative;
                                top: -5px;
                                left: -5px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                img{
                                    width: 80%;
                                    height: 80%;
                                }
                            }
                            .texte{
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: center;
                                min-width: 200px;
                                width: 70%;
                                h2{
                                    font-size: 30px;
                                    // font-family: 'Gilda Display', serif;
                                    font-weight: bold;
                                    color: black;
                                }
                                span{
                                    font-size: 15px;
                                    font-weight: bold;
                                    position: relative;
                                    top: -10px;
                                }
                            }
                            .send{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                transform: rotate(-30deg);
                                .SendSharpIcon{
                                    font-size: 35px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}