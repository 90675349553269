@import "../../style.scss";


@include themify($themes) {
    .promutemusic{
        background-color: #282828;
        width: 100%;
        // height: 100%;
        padding-bottom: 100px;
        .addMusic-navbar{
            position: relative;
            width: 100%;
            // height: 80px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: black;
            text-align: center;
            color: red;
            .logo{
                width: 30%;
                display: flex;
                justify-content: left;
            }
            .cancel{
                position: relative;
                display: flex;
                right: 20px;
                width: 30%;
                justify-content: flex-end;
                color: red;
            }
            .respect{
                text-align: center;
                // font-size: 30px;
                width: 40%;
            }
        }

        .promute-details{
            width: 700px;
            @include mobile{
                width: 80%;
            }
            @include tablet{
                width: 80%;
            }
            margin-right: auto;
            margin-left: auto;
            display: flex;
            flex-direction: column;
            .paiement{
                width: 100%;
                // background-color: white;
                display: flex;
                flex-direction: column;
                padding: 20px;
                border: 2px solid #ededed;
                border-radius: 5px;
                display: block;
                color: white;
                .paiement-middle{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    h5{
                        font-size: 16px;
                    }
                    .paiement-middle-content{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        @include tablet{
                            flex-direction: column;
                            gap: 30px;
                        }
                        .paiement-middle-content-box{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: 30%;
                            @include tablet{
                                width: 100%;
        
                            }
                            .box{
                                display: flex;
                                flex-direction: column;
                                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                                justify-content: center;
                                height: 300px;
                                width: 100%;
                                padding: 10px;
                                border-radius: 10px;
                                cursor: pointer;
                                background-color: white;
                                @include tablet{
                                    width: 100%;
                                    height: auto;
                                }
                                .check-box{
                                    width: 15px;
                                    height: 15px;
                                    border: 1px solid black;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    @include tablet{
                                        width: 10px;
                                        height: 10px;
                                    }
                                    .icon{
                                        font-size: 10px;
                                        display: none;
                                        color: white;
                                        @include tablet{
                                            
                                            font-size: 5px;
                                        }
                                    }
                                }
                                .check-box-content{
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    width: 100%;
                                    height: 80%;
                                    gap: 20px;
                                    color: #000000;
                                    .title{
                                        font-size: 16px;
                                        font-weight: bold;
                                    }
                                    .prix{
                                        font-size: 24px;
                                        font-weight: bold;
                                    }
                                    .description{
                                        font-size: 10px;
                                        text-align: center;
                                    }
                                }
                                .check-img{
                                    display: flex;
                                    width: 100%;
                                    justify-content: center;
                                    align-content: center;
                                    height: 80%;
                                    img{
                                        width: 60px;
                                        object-fit: cover;
                                        @include tablet{
                                            width: 30px;
                                            height: 20px;
                                        }
                                    }
                                }
                            }
                            .box.active{
                                border: 4px solid red;
                                .check-box{
                                    background-color: red;
                                    border: none;
                                    .icon{
                                        display: block;
                                    }
                                }
                            }
                            .box:hover{
                                box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                                transition: .5s;
                            }
                            .txt{
                                width: 150px;
                                text-align: center;
                                font-weight: 100;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .payement-bottom{
                    display: none;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: 30px;
                    label{
                        width: 50%;
                        input{
                            width: 95%;
                        }
                        input::placeholder{
                            opacity: .5;
                        }
                        @include tablet{
                            font-size: 10px;
                        }
                    }
                }
                .payement-bottom.active{
                    display: flex;
                }
            }
            .confirmation{
                display: flex;
                flex-direction: column;
                .txt{
                    font-weight: 100;
                    font-size: 12px;
                    color: white;
                }
            }
            button{
                width: 300px;
                height: 50px;
                background-color: red;
                border: none;
                color: white;
                cursor: pointer;
                @include tablet{
                    width: 100%;
                }

            }
        }
    }
}