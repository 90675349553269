@import "../../style.scss";

@include themify($themes) {
    .evenements{
        .ligne{
            .colonne1{
                .card{
                    background-image: url(/assets/concert.jpg);
                    width: 100%;
                    height: 225px;
                }
                margin-bottom: 50px;
            }
        }
        .info{
            height: 45px;
            // background-color: red;
            margin-top: 10px;
            display: flex;
            gap: 10px;
            align-items: center;
            .icon{
                font-weight: bold;
                font-size: 20px;
                color: white;
            }
            .btn{
                background-color: red;
                color: white;
            }
        }
        .card-body{
            display: flex;
            flex-direction: column;
            .desc{
                font-weight: bold;
                font-family: 'Gilda Display', serif;
            }
            .price-box{
                display: flex;
                justify-content: space-between;
            }
        }
    }

}