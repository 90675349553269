@import "../../style.scss";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap");

body {
    background-color: black;
}


.main_blog{
    .titre{
        text-align: center;
        color: red;
        padding-top: 350px;
    }

    .app {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        //padding-top: 7%;
        margin-bottom: 5%;
        margin-left: 150px;
        margin-right: 150px;
        padding-top: 150px;
    
        @media (max-width: 950px) {
            margin-left: 40px;
            margin-right: 5px;
            //padding-top: 10%;
        }
    }
}