@import "../../style.scss";


@include themify($themes) {
    .addMusic{
        background-color: #282828;
        width: 100%;
        // height: 100%;
        padding-bottom: 100px;
        .addMusic-navbar{
            position: relative;
            width: 100%;
            // height: 80px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: black;
            text-align: center;
            color: red;
            .logo{
                width: 30%;
                display: flex;
                justify-content: left;
            }
            .cancel{
                position: relative;
                display: flex;
                right: 20px;
                width: 30%;
                justify-content: flex-end;
                color: red;
            }
            .respect{
                text-align: center;
                // font-size: 30px;
                width: 40%;
            }
        }
        .music-details{
            // background-color: red;
            width: 700px;
            @include mobile{
                width: 80%;
            }
            @include tablet{
                width: 80%;
            }
            margin-right: auto;
            margin-left: auto;
            display: flex;
            flex-direction: column;
            h2{
                color: white;
            }
            .form{
                display: flex;
                flex-direction: column;
                gap: 30px;
                color: white;
                input, select{
                    height: 40px;
                    background-color: transparent;
                    outline: none;
                    border: 1px solid white;
                    color:rgb(255, 255, 255, .5);
                }
                select{
                    appearance: none;
                    background-color: #282828;
                }
                input::placeholder{
                    color: white;
                    opacity: .5;
                }
                .selectoption{
                    select{
                        position: absolute;
                        width: 700px;
                        left: 0;
                    }
                }
                .no-audio{
                    border: 1px solid white;
                    text-align: center;
                    color: rgb(255, 255, 255, .5);
                    cursor: pointer;
                    display: flex;
                    p{
                        
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .addAudioIcon{
                            color: white;
                            font-size: 50px;
                        }
                    }
                }
                .have-audio{
                    display: flex;
                    cursor: pointer;
                    justify-content: space-between;
                    gap: 20px;
                    audio{

                        width: 80%;
                    }
                    .changer-music{
                        background-color: red;
                        width: 20%;
                        border-radius: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                    }
                }
                .cov{
                    display: flex;
                    gap: 30px;
                    @include mobile{
                        flex-direction: column;
                    }
                    @include tablet{
                        flex-direction: column;
                    }
                    .image{
                        width: 320px;
                        @include tablet{
                            width: auto;
                        }                        
                        height: 300px;
                        border: 1px solid white;
                        text-align: center;
                        color: rgb(255, 255, 255, .5);
                        img{
                            width: 100%;
                            height: 100%;
                        }
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        p{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            .addAudioIcon{
                                color: white;
                                font-size: 50px;
                            }
                        }
                    }
                    .description{
                        width: 350px;
                        font-size: 12px;
                        @include tablet{
                            width: auto;
                        }
                        color: white;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .point{
                            width: 10px;
                            position: relative;
                            top: 5px;
                            text-align: center;
                        }
                        .generate-cover{
                            position: relative;
                            margin-top: 10px;
                            font-size: 16px;
                        }
                        .generate-cover-contain{
                            position: fixed;
                            top: 50%;
                            left: 50%;
                            height: 200px;
                            width: 400px;
                            background-color: white;
                            transform: translate(-50%, -50%);
                            width: 30%;
                            color:#000000;
                            text-align: center;
                            padding: 20px;
                            border-radius: 20px;
                            font-weight: bold;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                        }
                    }
                }
                button{
                    width: 300px;
                    height: 50px;
                    background-color: red;
                    border: none;
                    color: white;
                    cursor: pointer;
                    @include tablet{
                        width: 100%;
                    }

                }
                .message-promotion{
                    .price{
                        color: green;
                        font-weight: bold;
                    }
                }
                .paiement{
                    width: 100%;
                    // background-color: white;
                    display: flex;
                    flex-direction: column;
                    padding: 20px;
                    border: 2px solid #ededed;
                    border-radius: 5px;
                    display: none;
                    .paiement-middle{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        h5{
                            font-size: 16px;
                        }
                        .paiement-middle-content{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            @include tablet{
                                flex-direction: column;
                                gap: 30px;
                            }
                            .paiement-middle-content-box{
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                width: 30%;
                                @include tablet{
                                    width: 100%;

                                }
                                .box{
                                    display: flex;
                                    flex-direction: column;
                                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                                    justify-content: center;
                                    height: 300px;
                                    width: 100%;
                                    padding: 10px;
                                    border-radius: 10px;
                                    cursor: pointer;
                                    background-color: white;
                                    @include tablet{
                                        width: 100%;
                                        height: auto;
                                    }
                                    .check-box{
                                        width: 15px;
                                        height: 15px;
                                        border: 1px solid black;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        @include tablet{
                                            width: 10px;
                                            height: 10px;
                                        }
                                        .icon{
                                            font-size: 10px;
                                            display: none;
                                            color: white;
                                            @include tablet{
                                                
                                                font-size: 5px;
                                            }
                                        }
                                    }
                                    .check-box-content{
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: center;
                                        width: 100%;
                                        height: 80%;
                                        gap: 20px;
                                        color: #000000;
                                        .title{
                                            font-size: 16px;
                                            font-weight: bold;
                                        }
                                        .prix{
                                            font-size: 24px;
                                            font-weight: bold;
                                        }
                                        .description{
                                            font-size: 10px;
                                            text-align: center;
                                        }
                                    }
                                    .check-img{
                                        display: flex;
                                        width: 100%;
                                        justify-content: center;
                                        align-content: center;
                                        height: 80%;
                                        img{
                                            width: 60px;
                                            object-fit: cover;
                                            @include tablet{
                                                width: 30px;
                                                height: 20px;
                                            }
                                        }
                                    }
                                }
                                .box.active{
                                    border: 4px solid red;
                                    .check-box{
                                        background-color: red;
                                        border: none;
                                        .icon{
                                            display: block;
                                        }
                                    }
                                }
                                .box:hover{
                                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                                    transition: .5s;
                                }
                                .txt{
                                    width: 150px;
                                    text-align: center;
                                    font-weight: 100;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                    .payement-bottom{
                        display: none;
                        justify-content: space-between;
                        width: 100%;
                        margin-top: 30px;
                        label{
                            width: 50%;
                            input{
                                width: 95%;
                            }
                            input::placeholder{
                                opacity: .5;
                            }
                            @include tablet{
                                font-size: 10px;
                            }
                        }
                    }
                    .payement-bottom.active{
                        display: flex;
                    }
                }
                .paiement.active{
                    display: block;
                }
                .confirmation{
                    display: flex;
                    flex-direction: column;
                    .txt{
                        font-weight: 100;
                        font-size: 12px;
                    }
                    .box-btn{
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 10px;
                        .btn-pay{
                            background-color: themed(btn-color);
                            border: none;
                            border-radius: 5px;
                            height: 50px;
                            color: white;
                            width: 150px;
                        }
                        .btn-ret{
                            background-color: transparent;
                            border: none;
                            color: themed(btn-color);
                            font-weight: 500;
                        }

                        .svg {
                            width: 3.25em;
                            transform-origin: center;
                            animation: rotate4 2s linear infinite;
                           }
                           
                           circle {
                            fill: none;
                            stroke: white;
                            stroke-width: 2;
                            stroke-dasharray: 1, 200;
                            stroke-dashoffset: 0;
                            stroke-linecap: round;
                            animation: dash4 1.5s ease-in-out infinite;
                           }
                           
                           @keyframes rotate4 {
                            100% {
                             transform: rotate(360deg);
                            }
                           }
                           
                           @keyframes dash4 {
                            0% {
                             stroke-dasharray: 1, 200;
                             stroke-dashoffset: 0;
                            }
                           
                            50% {
                             stroke-dasharray: 90, 200;
                             stroke-dashoffset: -35px;
                            }
                           
                            100% {
                             stroke-dashoffset: -125px;
                            }
                           }
                           

                    }
                }
                .radio-input input {
                    display: none;
                }
                
                .radio-input {
                --container_width: 100%;
                position: relative;
                display: flex;
                align-items: center;
                border-radius: 10px;
                background-color: #fff;
                color: #000000;
                width: var(--container_width);
                overflow: hidden;
                border: 1px solid rgba(53, 52, 52, 0.226);
                }
                
                .radio-input label {
                    .promute-yes{
                        background-color: #ff1616;
                    }
                    .promute-no{
                        background-color: #ff1616;
                    }
                    width: 100%;
                    padding: 10px;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1;
                    font-weight: 600;
                    letter-spacing: -1px;
                    font-size: 14px;
                }
                
                .selection {
                display: none;
                position: absolute;
                height: 100%;
                width: calc(var(--container_width) / 2);
                z-index: 0;
                left: 0;
                top: 0;
                transition: .15s ease;
                }
                
                .radio-input label:has(input:checked) {
                color: #fff;
                }
                
                .radio-input label:has(input:checked) ~ .selection {
                background-color: #ff1616;
                display: inline-block;
                }
                
                .radio-input label:nth-child(1):has(input:checked) ~ .selection {
                transform: translateX(calc(var(--container_width) * 0/2));
                }
                
                .radio-input label:nth-child(2):has(input:checked) ~ .selection {
                transform: translateX(calc(var(--container_width)));
                }
            }
        }
    }
}